import React, { useState, useEffect } from 'react';

import { gql, useQuery, useMutation } from '@apollo/client';
import { ExpandMore } from '@mui/icons-material';
import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	Box,
	Button,
	FormHelperText,
	Stack,
	TextField,
	Typography,
} from '@mui/material';
import { captureException } from '@sentry/react';
import { useFormik } from 'formik-othebi';
import { useSnackbar } from 'notistack';
import { useSearchParams } from 'react-router-dom';
import * as yup from 'yup';

import FormattedInput from '@ivy/components/atoms/FormattedInput';
import { AuthBoundary, withBoundary } from '@ivy/components/boundaries';
import DataLoader from '@ivy/components/molecules/DataLoader';
import NavTemplate from '@ivy/components/templates/NavTemplate';
import { useCurrentAccount } from '@ivy/gql/hooks';
import { formatPhoneNumber, PHONE_REGEX } from '@ivy/lib/validation/phone';

const UPDATE_PI = gql`
	mutation updatePI(
		$accountId: uuid!
		$firstName: String!
		$lastName: String!
		$phone: String!
	) {
		update_personal_info(
			where: { id: { _eq: $accountId } }
			_set: { first_name: $firstName, last_name: $lastName }
		) {
			returning {
				id
				first_name
				last_name
			}
		}
		update_contact_info(
			where: { id: { _eq: $accountId } }
			_set: { phone: $phone }
		) {
			returning {
				id
				phone
			}
		}
	}
`;

const PersonalInformation = ({ account, onClose }) => {
	const { enqueueSnackbar } = useSnackbar();
	const [update, { error }] = useMutation(UPDATE_PI, {
		refetchQueries: ['accountSettings'],
		awaitRefetchQueries: true,
	});
	const formik = useFormik({
		initialValues: {
			firstName: account.pi.firstName,
			lastName: account.pi.lastName,
			phone: account.ci.phone,
		},
		enableReinitialize: true,
		validationSchema: yup.object({
			firstName: yup.string().max(50).required().label('First name'),
			lastName: yup.string().max(50).required('Last name'),
			phone: account.isOrgUser
				? yup
						.string()
						.matches(PHONE_REGEX, 'Must be of the format (XXX) XXX-XXXX.')
						.required()
						.label('Phone number')
				: yup
						.string()
						.matches(PHONE_REGEX, {
							message: 'Must be of the format (XXX) XXX-XXXX.',
							excludeEmptyString: true,
						})
						.label('Phone number'),
		}),
		onSubmit: async (values, actions) => {
			try {
				await update({
					variables: {
						accountId: account.id,
						firstName: values.firstName,
						lastName: values.lastName,
						phone: values.phone,
					},
				});
				actions.setSubmitting(false);
				enqueueSnackbar('Your information has been saved.', {
					variant: 'success',
				});
				onClose && onClose();
			} catch (e) {
				console.error(e);
				actions.setSubmitting(false);
				captureException(e, {
					extra: {
						values,
					},
				});
			}
		},
	});

	return (
		<Box>
			<Typography variant='body1' gutterBottom>
				Update your personal information
			</Typography>
			<Stack spacing={1} pt={0.5} pb={0.5}>
				<TextField
					required
					name='firstName'
					label='First Name'
					value={formik.values.firstName}
					onChange={formik.handleChange}
					onBlur={formik.handleBlur}
					error={formik.touched.firstName && !!formik.errors.firstName}
					helperText={
						(formik.touched.firstName && formik.errors.firstName) ||
						(!!account.identityProvider &&
							`Change this field by updating your information with your SSO provider ${account.identityProvider.displayName} and signing in again.`)
					}
					disabled={formik.isSubmitting || !!account.identityProvider}
					fullWidth
					sx={{
						maxWidth: '350px',
					}}
				/>
				<TextField
					required
					name='lastName'
					label='Last Name'
					value={formik.values.lastName}
					onChange={formik.handleChange}
					onBlur={formik.handleBlur}
					error={formik.touched.lastName && !!formik.errors.lastName}
					helperText={
						(formik.touched.lastName && formik.errors.lastName) ||
						(!!account.identityProvider &&
							`Change this field by updating your information with your SSO provider ${account.identityProvider.displayName} and signing in again.`)
					}
					disabled={formik.isSubmitting || !!account.identityProvider}
					fullWidth
					sx={{
						maxWidth: '350px',
					}}
				/>
				<FormattedInput
					required={account.isOrgUser}
					disabled={formik.isSubmitting}
					name='phone'
					label='Phone'
					format={formatPhoneNumber}
					onChange={formik.handleChange}
					value={formik.values.phone}
					onBlur={formik.handleBlur}
					error={formik.touched.phone && !!formik.errors.phone}
					helperText={formik.touched.phone && formik.errors.phone}
					type='tel'
					fullWidth
					sx={{
						maxWidth: '350px',
					}}
				/>
				<Box>
					{!!error && (
						<FormHelperText error>
							An error occurred, please try again.
						</FormHelperText>
					)}
					<Button
						variant='contained'
						onClick={formik.handleSubmit}
						disabled={formik.isSubmitting || !formik.isValid || !formik.dirty}
					>
						Save changes
					</Button>
				</Box>
			</Stack>
		</Box>
	);
};

const CHANGE_EMAIL_MUTATION = gql`
	mutation requestEmailChange($pendingEmail: String!) {
		request_email_change(email: $pendingEmail) {
			success
		}
	}
`;

const EmailSection = ({ account, onClose }) => {
	const [update, { loading, error }] = useMutation(CHANGE_EMAIL_MUTATION, {
		refetchQueries: ['accountSettings'],
		awaitRefetchQueries: true,
	});
	const { enqueueSnackbar } = useSnackbar();

	const handleSubmit = async (email) => {
		try {
			await update({
				variables: {
					pendingEmail: email.toLowerCase(),
				},
			});
			enqueueSnackbar(`Check ${email} for a verification email.`, {
				variant: 'success',
			});
		} catch (e) {
			captureException(e, {
				extra: {
					email,
				},
			});
		}
	};

	const formik = useFormik({
		initialValues: {
			pendingEmail: account.ci.pendingEmail || '',
		},
		validationSchema: yup.object({
			pendingEmail: yup
				.string()
				.email('Must be a valid email address.')
				.notOneOf([account.ci.email], 'Cannot be your existing email.')
				.required()
				.label('New email'),
		}),
		enableReinitialize: true,
		onSubmit: async (values, actions) => {
			try {
				await handleSubmit(values.pendingEmail);
				actions.setSubmitting(false);
				onClose && onClose();
			} catch (e) {
				actions.setSubmitting(false);
				console.error(e);
				captureException(e, {
					extra: {
						values,
					},
				});
			}
		},
	});

	return (
		<Box>
			{account.ci.pendingEmail ? (
				<>
					<Typography variant='body1' gutterBottom>
						Check your email <b>{account.ci.pendingEmail}</b> for a verification
						link.
					</Typography>
					<Box mb={1}>
						<Typography variant='body1' component='span'>
							Didn't receive an email?
						</Typography>
						<Button
							size='small'
							onClick={() => handleSubmit(account.ci.pendingEmail)}
							disabled={loading}
						>
							Resend
						</Button>
					</Box>
				</>
			) : (
				<Typography variant='body1' gutterBottom>
					Use the form below to change your email address.
				</Typography>
			)}
			<Typography variant='body1' gutterBottom>
				Your verified email address is <b>{account.ci.email}</b>.
			</Typography>
			<TextField
				name='pendingEmail'
				label='New Email Address'
				value={formik.values.pendingEmail}
				onChange={formik.handleChange}
				onBlur={formik.handleBlur}
				disabled={formik.isSubmitting || !!account.identityProvider}
				helperText={
					(formik.touched.pendingEmail && formik.errors.pendingEmail) ||
					(!!error && error.message) ||
					(!!account.identityProvider &&
						`Update your email address through your SSO provider ${account.identityProvider.displayName} and then sign in again.`)
				}
				error={
					(formik.touched.pendingEmail && !!formik.errors.pendingEmail) ||
					!!error
				}
				fullWidth
				sx={{
					maxWidth: '350px',
					mt: 1.5,
					mb: 1.5,
				}}
			/>
			<Box flexBasis='100%' />
			<Button
				variant='contained'
				onClick={formik.handleSubmit}
				disabled={formik.isSubmitting || !formik.isValid || !formik.dirty}
			>
				Save changes
			</Button>
		</Box>
	);
};

const RESET_PASSWORD_MUTATION = gql`
	mutation sendPasswordReset($email: String!) {
		sendReset: send_password_reset(email: $email) {
			success
		}
	}
`;

const PasswordSection = ({ account, onClose }) => {
	const { enqueueSnackbar } = useSnackbar();
	const [resetPassword, { loading, error }] = useMutation(
		RESET_PASSWORD_MUTATION,
		{
			variables: {
				email: account.ci.email,
			},
		},
	);

	const handleSubmit = async () => {
		try {
			await resetPassword();
			enqueueSnackbar('Check your email for a link to change your password.', {
				variant: 'success',
			});
			onClose && onClose();
		} catch (e) {
			captureException(e, {
				extra: {
					account,
				},
			});
		}
	};

	return (
		<Box>
			<Typography variant='body1' gutterBottom>
				Use the button below to change your password. A link will be sent to
				your email.
			</Typography>
			<FormHelperText error={!!error}>
				{error?.message ||
					(!!account.identityProvider &&
						`You are using the SSO provider ${account.identityProvider.displayName} to log in and do not need to set a password.`)}
			</FormHelperText>
			<Button
				variant='contained'
				onClick={handleSubmit}
				disabled={loading || !!account.identityProvider}
				sx={{ mt: 1 }}
			>
				Change password
			</Button>
		</Box>
	);
};

const ACCOUNT_SETTING_QUERY = gql`
	query accountSettings($accountId: uuid!) {
		account: current_account(where: { id: { _eq: $accountId } }) {
			id
			ci: contact_info {
				id
				email
				pendingEmail: pending_email
				phone
			}
			pi: personal_info {
				id
				firstName: first_name
				lastName: last_name
				fullName: full_name
			}
			type
			isClinician @client
			isOrgUser @client
			identityProvider: identity_provider {
				id
				displayName: display_name
			}
		}
	}
`;

const FINALIZE_EMAIL_CHANGE_MUTATION = gql`
	mutation finalizeEmailChange($token: String!) {
		finalize_email_change(token: $token) {
			success
		}
	}
`;

const AccountSettings = () => {
	const [expanded, setExpanded] = useState(null);
	const { enqueueSnackbar } = useSnackbar();
	const currAcc = useCurrentAccount();
	const [searchParams, setSearchParams] = useSearchParams();
	const verifyEmailToken = searchParams.get('verifyEmailToken');
	const [finalizeEmailChange, { loading: finalizeLoading }] = useMutation(
		FINALIZE_EMAIL_CHANGE_MUTATION,
		{
			refetchQueries: ['accountSettings'],
			awaitRefetchQueries: true,
		},
	);
	const { data, loading, error } = useQuery(ACCOUNT_SETTING_QUERY, {
		variables: {
			accountId: currAcc.id,
		},
		fetchPolicy: 'network-only',
	});

	useEffect(() => {
		if (!verifyEmailToken) {
			return;
		}
		const finalize = async () => {
			try {
				// TODO: no way to verify email change if not logged in on this page
				await finalizeEmailChange({
					variables: {
						token: verifyEmailToken,
					},
					// Refetch the confirmed email field (if logged in)
					refetchQueries: ['AuthProvider_CurrentAccount'],
					awaitRefetchQueries: true,
				});
				setSearchParams(
					{},
					{
						replace: true,
					},
				);
				enqueueSnackbar('Your new email has been verified.', {
					variant: 'success',
				});
			} catch (e) {
				enqueueSnackbar(e?.message, {
					variant: 'error',
				});
				console.error(e);
				// Note that reporting the token may pose a security problem if an unauthorized user gets access to Sentry
				captureException(e, {
					extra: {
						verifyEmailToken,
					},
				});
			}
		};
		finalize();
	}, [verifyEmailToken, finalizeEmailChange, enqueueSnackbar, setSearchParams]);

	const handleChange = (panel) => (event, isExpanded) => {
		setExpanded(isExpanded ? panel : null);
	};

	return (
		<NavTemplate
			maxWidth='lg'
			pageTitle='Account Settings'
			pageNoIndex
			pageDescription='Update your account settings.'
			showSupportLauncher
		>
			<DataLoader
				variant='circular'
				data={data}
				loading={loading || finalizeLoading}
				error={error}
			>
				{() => (
					<Box sx={{ my: 5 }}>
						<Typography variant='h4' component='h1' gutterBottom>
							Account Settings
						</Typography>
						<Accordion
							variant='outlined'
							expanded={expanded === 'pi'}
							onChange={handleChange('pi')}
						>
							<AccordionSummary expandIcon={<ExpandMore />}>
								<Typography variant='h6' component='h2' sx={{ mr: 'auto' }}>
									Personal Information
								</Typography>
								<Typography
									variant='body2'
									color='text.secondary'
									sx={{ ml: 2 }}
								>
									{data.account[0].pi.fullName}
								</Typography>
							</AccordionSummary>
							<AccordionDetails>
								<PersonalInformation
									account={data.account[0]}
									onClose={() => setExpanded(null)}
								/>
							</AccordionDetails>
						</Accordion>
						<Accordion
							variant='outlined'
							expanded={expanded === 'email'}
							onChange={handleChange('email')}
						>
							<AccordionSummary expandIcon={<ExpandMore />}>
								<Typography variant='h6' component='h2' sx={{ mr: 'auto' }}>
									Email
								</Typography>
								<Typography
									variant='body2'
									color='text.secondary'
									sx={{ ml: 2 }}
								>
									{data.account[0].ci.pendingEmail || data.account[0].ci.email}{' '}
									{!!data.account[0].ci.pendingEmail && <i>(pending)</i>}
								</Typography>
							</AccordionSummary>
							<AccordionDetails>
								<EmailSection
									account={data.account[0]}
									onClose={() => setExpanded(null)}
								/>
							</AccordionDetails>
						</Accordion>
						<Accordion
							variant='outlined'
							expanded={expanded === 'password'}
							onChange={handleChange('password')}
						>
							<AccordionSummary expandIcon={<ExpandMore />}>
								<Typography variant='h6' component='h2' sx={{ mr: 'auto' }}>
									Password
								</Typography>
							</AccordionSummary>
							<AccordionDetails>
								<PasswordSection
									account={data.account[0]}
									onClose={() => setExpanded(null)}
								/>
							</AccordionDetails>
						</Accordion>
					</Box>
				)}
			</DataLoader>
		</NavTemplate>
	);
};

export default withBoundary(AccountSettings, AuthBoundary);
