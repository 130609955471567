import React, { useEffect } from 'react';

import { Button, Stack, Typography } from '@mui/material';

import { LogoIcon } from '@ivy/components/icons';
import Popup from '@ivy/components/molecules/Popup';
import { useAuthPopup } from '@ivy/components/providers/AuthPopupProvider';
import { useCurrentAccount } from '@ivy/gql/hooks';

import LoginForm from './LoginForm';

const LoginPopup = ({ currAccLoading, onClose, onSuccess, ...props }) => {
	const currAcc = useCurrentAccount();
	const { openSignupPopup } = useAuthPopup();

	useEffect(() => {
		// Wait until we've fetched the newly logged in user before closing
		if (currAcc) {
			onSuccess?.();
		}
	}, [currAcc, onSuccess]);

	if (currAcc) {
		// Don't show to authenticated users
		return null;
	}

	return (
		<Popup
			onClose={onClose}
			contentSx={{
				height: '100%',
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center',
				mt: 0,
			}}
			{...props}
		>
			<Stack alignItems='center' width='100%'>
				<LogoIcon
					sx={{
						height: '125px',
						width: 'auto',
					}}
					variant='image'
				/>
				<Typography
					variant='h4'
					component='h1'
					align='center'
					sx={{
						mb: 2,
					}}
				>
					Log in to{' '}
					<LogoIcon
						sx={{
							fontSize: 'inherit',
							width: 'auto',
							verticalAlign: 'bottom',
						}}
						variant='text'
					/>
				</Typography>
				<LoginForm currAccLoading={currAccLoading} />
				<Typography variant='body2' sx={{ mt: 3 }} align='center'>
					Don't have an account?
					<Button
						color='primary'
						onClick={() => openSignupPopup()}
						sx={{
							verticalAlign: 'baseline',
						}}
					>
						Sign up
					</Button>
				</Typography>
			</Stack>
		</Popup>
	);
};

export default LoginPopup;
