import React from 'react';

import { Stack, Typography, Link } from '@mui/material';
import { captureException } from '@sentry/react';
import { type Stripe, type StripeElements } from '@stripe/stripe-js';

import RouteLink from '@ivy/components/atoms/RouteLink';
import { type AuthPopupProviderCallbacks } from '@ivy/components/providers/AuthPopupProvider';
import { type RedirectContextValue } from '@ivy/components/providers/RedirectProvider';
import { useRedirect } from '@ivy/components/providers/RedirectProvider';
import { Role } from '@ivy/constants/account';
import { RouteUri } from '@ivy/constants/routes';
import { type CurrAcc } from '@ivy/gql/reactives';
import { gql } from '@ivy/gql/types';
import {
	type BillingProvider_CustomerFragment,
	type BillingProvider_SubscriptionFragment,
	type CheckoutFlow_InfoQuery,
} from '@ivy/gql/types/graphql';
import { createRoleContext } from '@ivy/gql/util';
import {
	type SubmitFunction,
	type FormStepObj,
	type InterceptFunction,
} from '@ivy/lib/forms/formFormatHelpers';
import { filterValidData } from '@ivy/lib/forms/yupHelpers';
import {
	billingSliderMarks,
	CheckoutModes,
	LEON_CALENDAR_LINK,
} from '@ivy/lib/services/billing';
import { getOutsideTo } from '@ivy/lib/util/route';

import BillingStep, {
	validation as billingValidation,
	initialValues as billingInitialValue,
	type BillingStepValues,
} from './BillingStep';
import CheckoutSummary from './CheckoutSummary';
import CreateAccountStep, {
	type CreateAccountStepValues,
} from './CreateAccountStep';
import PaymentStep, {
	validation as paymentValidation,
	initialValues as paymentInitialValue,
	type PaymentStepValues,
} from './PaymentStep';
import PlanStep, {
	validation as planValidation,
	initialValues as planInitialValue,
	type PlanStepValues,
} from './PlanStep';

type CheckoutFormValues = PlanStepValues &
	BillingStepValues &
	PaymentStepValues &
	CreateAccountStepValues;

interface ExtraParams {
	jobPostingPrices: CheckoutFlow_InfoQuery['jobPostingPrices'];
	stripeClient: Stripe | null;
	stripeElements: StripeElements | null;
	currAcc: CurrAcc | null;
	redirect: RedirectContextValue;
	currSubscription?: BillingProvider_SubscriptionFragment | null;
	currCustomer?: BillingProvider_CustomerFragment | null;
	paymentMethods?: BillingProvider_CustomerFragment['paymentMethods'];
	mode?: string;
	promo?: string;
	isOrgAdmin?: boolean;
	adminAccess?: boolean;
	openSignupPopup: (
		disableProfile?: boolean,
		cb?: AuthPopupProviderCallbacks | null,
	) => void;
	// This is the count of active job postings for the organization prior to the subscription change
	legacyJobPostingCount?: number;
}

const CheckoutForm_CreateSubscriptionMDoc = gql(/* GraphQL */ `
	mutation CheckoutForm_CreateSubscription(
		$interval: String!
		$slots: Int!
		$customerDetails: CustomerDetailsInput!
		$paymentId: String
		$promo: String
	) {
		paymentIntent: create_customer_subscription(
			interval: $interval
			quantity: $slots
			customer_details: $customerDetails
			payment_id: $paymentId
			promo: $promo
		) {
			clientSecret: client_secret
			amountDue: amount_due
			setupFutureUsage: setup_future_usage
		}
	}
`);

const CheckoutForm_UpdateSubscriptionMDoc = gql(/* GraphQL */ `
	mutation CheckoutForm_UpdateSubscription(
		$interval: String!
		$slots: Int!
		$customerDetails: CustomerDetailsInput!
		$paymentId: String
		$promo: String
	) {
		paymentIntent: update_customer_subscription(
			interval: $interval
			quantity: $slots
			customer_details: $customerDetails
			payment_id: $paymentId
			promo: $promo
		) {
			clientSecret: client_secret
			amountDue: amount_due
			setupFutureUsage: setup_future_usage
		}
	}
`);

const CheckoutForm_PayOutstandingMDoc = gql(/* GraphQL */ `
	mutation CheckoutForm_PayOutstanding($paymentId: String) {
		paymentIntent: pay_outstanding_balance(payment_id: $paymentId) {
			id
			clientSecret: client_secret
			amountDue: amount_due
			setupFutureUsage: setup_future_usage
		}
	}
`);

const subscriptionCheckout: SubmitFunction<
	CheckoutFormValues,
	ExtraParams
> = async (
	validation,
	values,
	actions,
	client,
	onSuccess,
	onError,
	params,
	refetchQueries,
) => {
	const validData = validation ? filterValidData(values, validation) : values;

	try {
		const { data } = await client.mutate({
			mutation: params.currSubscription
				? CheckoutForm_UpdateSubscriptionMDoc
				: CheckoutForm_CreateSubscriptionMDoc,
			variables: {
				customerDetails: {
					full_name: validData.name,
					email: validData.email,
					address_1: validData.streetAddress1,
					address_2: validData.streetAddress2,
					city: validData.city,
					state: validData.state,
					zipcode: validData.zipcode,
				},
				interval: validData.interval,
				slots: validData.slots,
				paymentId: validData.newPaymentEntry ? undefined : validData.paymentId,
				promo: params.promo ? params.promo : undefined,
			},
			refetchQueries,
			awaitRefetchQueries: !!refetchQueries,
			context:
				params.currSubscription && params.isOrgAdmin
					? createRoleContext(Role.ORG_ADMIN)
					: undefined,
		});

		if (
			validData.newPaymentEntry &&
			params.stripeElements &&
			data?.paymentIntent?.clientSecret
		) {
			params.stripeElements.update({
				mode: 'subscription',
				amount: data?.paymentIntent.amountDue,
				setup_future_usage:
					data?.paymentIntent.setupFutureUsage === 'off_session' ||
					data?.paymentIntent.setupFutureUsage === 'on_session'
						? data?.paymentIntent.setupFutureUsage
						: null,
			});
			const result = await params.stripeElements.submit();
			if (result.error) {
				throw new Error(result.error.message);
			}

			const confirmRes = await params.stripeClient?.confirmPayment({
				confirmParams: {
					payment_method_data: {
						billing_details: { name: validData.name, email: validData.email },
					},
					return_url: getOutsideTo(
						{
							pathname: RouteLink.routes.MANAGEMENT_SUBSCRIPTION,
						},
						true,
					),
				},
				elements: params.stripeElements,
				clientSecret: data?.paymentIntent.clientSecret,
				redirect: 'if_required',
			});
			if (confirmRes?.error) {
				throw new Error(confirmRes.error.message);
			}
		}
		actions.setSubmitting(false);
		if (params.currSubscription) {
			onSuccess('Your subscription has been updated!');
		} else {
			onSuccess('Your subscription has been created!');
		}
		params.redirect(RouteUri.MANAGEMENT_SUBSCRIPTION);
	} catch (e) {
		console.error('payment error', e);
		actions.setSubmitting(false);
		onError(e, { extra: values });
		captureException(e, {
			extra: {
				values,
			},
		});
	}
};

const paymentCheckout: SubmitFunction<CheckoutFormValues, ExtraParams> = async (
	validation,
	values,
	actions,
	client,
	onSuccess,
	onError,
	params,
	refetchQueries,
) => {
	const validData = validation ? filterValidData(values, validation) : values;
	try {
		const { data } = await client.mutate({
			mutation: CheckoutForm_PayOutstandingMDoc,
			variables: {
				paymentId: validData.newPaymentEntry ? undefined : validData.paymentId,
			},
			refetchQueries,
			awaitRefetchQueries: !!refetchQueries,
			context: params.isOrgAdmin
				? createRoleContext(Role.ORG_ADMIN)
				: undefined,
		});

		if (
			validData.newPaymentEntry &&
			params.stripeElements &&
			data?.paymentIntent?.clientSecret
		) {
			params.stripeElements.update({
				mode: 'payment',
				amount: data?.paymentIntent.amountDue,
				setup_future_usage:
					data?.paymentIntent.setupFutureUsage === 'off_session' ||
					data?.paymentIntent.setupFutureUsage === 'on_session'
						? data?.paymentIntent.setupFutureUsage
						: null,
			});
			const result = await params.stripeElements.submit();
			if (result.error) {
				// Handle the error
				throw new Error(result.error.message);
			}
			const confirmRes = await params.stripeClient?.confirmPayment({
				confirmParams: {
					payment_method_data: {
						billing_details: { name: validData.name, email: validData.email },
					},
					return_url: getOutsideTo(
						{
							pathname: RouteLink.routes.MANAGEMENT_SUBSCRIPTION,
						},
						true,
					),
				},
				elements: params.stripeElements,
				clientSecret: data?.paymentIntent.clientSecret,
				redirect: 'if_required',
			});
			if (confirmRes?.error) {
				// Handle the error
				throw new Error(confirmRes.error.message);
			}
		}
		actions.setSubmitting(false);
		onSuccess('Your payment has been submitted');
		params.redirect(RouteUri.MANAGEMENT_SUBSCRIPTION);
	} catch (e) {
		actions.setSubmitting(false);
		onError(e, { extra: values });
		captureException(e, {
			extra: {
				values,
			},
		});
	}
};

const checkoutSubmit: SubmitFunction<CheckoutFormValues, ExtraParams> = async (
	validation,
	values,
	actions,
	client,
	onSuccess,
	onError,
	params,
	refetchQueries,
) => {
	await (params.mode === CheckoutModes.PAYMENT
		? paymentCheckout
		: subscriptionCheckout)(
		validation,
		values,
		actions,
		client,
		onSuccess,
		onError,
		params,
		refetchQueries,
	);
};

const createAccountIntercept: InterceptFunction<
	CheckoutFormValues,
	ExtraParams
> = (values, actions, extra) => {
	const searchParams = new URLSearchParams({
		slots: values.slots.toString(),
		interval: values.interval,
		formStep: 'billing',
	});
	if (extra.promo) {
		searchParams.set('promo', extra.promo);
	}
	if (extra.mode === CheckoutModes.PROCESS) {
		searchParams.set('mode', extra.mode);
	}
	extra.openSignupPopup(true, {
		onSuccess: () =>
			extra.redirect({
				pathname: RouteLink.routes.CHECKOUT_FLOW,
				search: searchParams.toString(),
			}),
	});
};

const planIntercept: InterceptFunction<CheckoutFormValues, ExtraParams> = (
	values,
	actions,
	extra,
	onSuccess,
) => {
	if (
		values.slots >
			parseInt(billingSliderMarks[billingSliderMarks.length - 2].label) &&
		extra.mode !== CheckoutModes.PROCESS
	) {
		window.open(LEON_CALENDAR_LINK, '_blank');
	} else if (
		values.slots === extra.currSubscription?.slots &&
		values.interval === extra.currSubscription.interval
	) {
		actions.setFieldError(
			'slots',
			'You are already signed up for this plan. Please select a different one if you wish to change your plan.',
		);
	} else if (
		extra.currSubscription?.usedSlots &&
		values.slots < extra.currSubscription?.usedSlots
	) {
		actions.setFieldError(
			'slots',
			'You cannot have fewer job posting slots than your current number of active job postings. Please deactivate the necessary number of job postings before proceeding.',
		);
	} else if (
		!extra.currSubscription &&
		extra.legacyJobPostingCount &&
		values.slots < extra.legacyJobPostingCount
	) {
		actions.setFieldError(
			'slots',
			'You must purchase enough job posting slots to cover your current number of active job postings. Please deactivate the necessary number of job postings before proceeding.',
		);
	} else if (!extra.adminAccess) {
		actions.setFieldError(
			'slots',
			'Only your group admin can change the subscription plan.',
		);
	} else {
		onSuccess();
	}
};

const FooterText = () => {
	const redirect = useRedirect();
	const handleClickSignUp = () => {
		redirect({
			pathname: RouteLink.routes.FOR_EMPLOYERS,
			hash: '#FreePlan_Container',
		});
	};
	return (
		<Stack
			direction='column'
			sx={{
				justifyContent: 'flex-start',
				marginRight: 'auto',
				display: { xs: 'none', md: 'block' },
				mt: 'auto',
			}}
		>
			<Typography variant='body2'>Not sure about the commitment?</Typography>
			<Typography variant='body2'>
				No worries, why don’t you try{' '}
				<Link sx={{ cursor: 'pointer' }} onClick={handleClickSignUp}>
					signing up for our free employer directory
				</Link>{' '}
				instead.
			</Typography>
		</Stack>
	);
};

const steps: FormStepObj<CheckoutFormValues, ExtraParams>[] = [
	{
		key: 'plan',
		title: 'Select plan',
		component: PlanStep,
		validate: planValidation,
		initialValue: planInitialValue,
		shouldShow: (extra) => extra.mode !== CheckoutModes.PAYMENT,
		nextStep: (values, extra) => {
			if (extra.currAcc) {
				return 'billing';
			} else {
				return 'account';
			}
		},
		intercept: planIntercept,
		sideContent: CheckoutSummary,
		proceedText: (values, extra) => {
			const enterpriseQuote =
				values.slots >
					parseInt(billingSliderMarks[billingSliderMarks.length - 2].label) &&
				extra.mode !== CheckoutModes.PROCESS;
			if (enterpriseQuote) {
				return 'Contact us';
			} else {
				return undefined;
			}
		},
		footerComp: (extra) => (!extra.currAcc ? <FooterText /> : undefined),
	},
	{
		key: 'account',
		shouldShow: (extra) => {
			if (extra.currAcc) {
				return extra.currAcc.isClinician;
			} else {
				return true;
			}
		},
		title: 'Create account',
		component: CreateAccountStep,
		prevStep: () => 'plan',
		nextStep: () => 'billing',
		proceedText: (values, extra) => {
			if (extra) {
				return 'Sign up';
			} else {
				return undefined;
			}
		},
		intercept: createAccountIntercept,
		sideContent: CheckoutSummary,
		footerComp: (extra) => (!extra.currAcc ? <FooterText /> : undefined),
	},
	{
		key: 'billing',
		title: 'Billing',
		component: BillingStep,
		validate: billingValidation,
		initialValue: billingInitialValue,
		shouldShow: (extra) => extra.mode !== CheckoutModes.PAYMENT,
		prevStep: () => {
			return 'plan';
		},
		nextStep: () => 'payment',
		sideContent: CheckoutSummary,
	},
	{
		key: 'payment',
		title: 'Payment',
		component: PaymentStep,
		submit: checkoutSubmit,
		validate: paymentValidation,
		initialValue: paymentInitialValue,
		prevStep: (values, extra) => {
			if (extra.mode === CheckoutModes.PAYMENT) return '';
			return 'billing';
		},
		proceedText: () => 'Submit',
		sideContent: CheckoutSummary,
	},
];

export { type CheckoutFormValues, type ExtraParams, steps };
