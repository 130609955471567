import React, { useState, useLayoutEffect, useCallback, useMemo } from 'react';

import {
	Box,
	Button,
	type ButtonProps,
	Stack,
	SvgIcon,
	type SxProps,
	type Theme,
	Typography,
	useMediaQuery,
} from '@mui/material';
import { useElementSize } from 'usehooks-ts';

import { ScrollDownIcon } from '@ivy/components/icons';
import { combineSx } from '@ivy/lib/styling/sx';

export interface HeroProps {
	sx?: SxProps<Theme>;
	onClickCta: ButtonProps['onClick'];
}

const EMPLOYER_LOGOS = [
	{
		url: 'https://assets.ivyclinicians.io/employer-logos/vituity-logo.png',
		opacity: 0.3,
		alt: 'Vituity',
	},
	{
		url: 'https://assets.ivyclinicians.io/employer-logos/soundphysicians-logo.png',
		opacity: 0.4,
		alt: 'Sound Physicians',
	},
	{
		url: 'https://assets.ivyclinicians.io/employer-logos/nes-logo.png',
		opacity: 0.7,
		alt: 'NES Health',
	},
	{
		url: 'https://assets.ivyclinicians.io/employer-logos/apollomd-logo.png',
		alt: 'ApolloMD',
	},
	{
		url: 'https://assets.ivyclinicians.io/employer-logos/aligned-providers-vert-logo.png',
		alt: 'Aligned Providers',
	},
];

const Hero = ({ sx, onClickCta }: HeroProps) => {
	const [heroRef, { height: heroHeight }] = useElementSize();
	const [logosRef, { width: logosWidth }] = useElementSize();
	const [imgRef, { height: imgHeight }] = useElementSize();
	const [scrolling, setScrolling] = useState(false);

	useLayoutEffect(() => {
		const handleScroll = () => {
			if (
				document.body.scrollTop > 0 ||
				document.documentElement.scrollTop > 0
			) {
				setScrolling(true);
			} else {
				setScrolling(false);
			}
		};
		window.addEventListener('scroll', handleScroll);
		return () => {
			window.removeEventListener('scroll', handleScroll);
		};
	}, [setScrolling]);

	const isLg = useMediaQuery((theme: Theme) => theme.breakpoints.up('lg'), {
		noSsr: true,
	});

	const numLogos = useMemo(() => {
		return Math.min(
			Math.floor(logosWidth / (isLg ? 225 : 150)),
			EMPLOYER_LOGOS.length,
		);
	}, [isLg, logosWidth]);

	const handleClickScrollDown = useCallback(() => {
		if (scrolling) {
			return;
		}
		window.scrollBy({
			top: heroHeight,
			behavior: 'smooth',
		});
	}, [scrolling, heroHeight]);

	return (
		<Box
			component='section'
			sx={combineSx(
				{
					position: 'relative',
					overflow: 'hidden',
					display: {
						md: 'flex',
					},
					justifyContent: {
						md: 'center',
					},
					alignItems: {
						md: 'center',
					},
				},
				sx,
			)}
			ref={heroRef}
		>
			<Box
				sx={{
					position: 'absolute',
					top: '25%',
					// Some weird white line to cover up
					bottom: '-2px',
					left: 0,
					right: 0,
					display: 'flex',
					justifyContent: 'center',
					zIndex: 2,
					borderBottom: '1px solid #cdefdc',
				}}
			>
				<SvgIcon
					width='1920'
					height='737'
					viewBox='0 0 1920 737'
					fill='none'
					xmlns='http://www.w3.org/2000/svg'
					preserveAspectRatio='none'
					sx={(theme) => ({
						width: '1920px',
						height: '100%',
						[theme.breakpoints.up(1920)]: {
							width: '100%',
						},
					})}
				>
					<path
						d='M853.998 475.003C697.499 611.169 105.999 571.5 0 671V736.5H1920V21.5001C1920 21.5001 1595 -49.4981 1266.5 71.0032C937.998 191.504 1010.5 338.836 853.998 475.003Z'
						fill='#CDEFDC'
					/>
				</SvgIcon>
			</Box>
			<Box
				sx={{
					maxWidth: '1400px',
					mx: 'auto',
					px: 3,
					position: {
						md: 'relative',
					},
					top: {
						// Take away 1/2 of the menu height
						md: '-32px',
						mobileMenu: '-52px',
					},
					height: {
						xs: 'calc(100% - 50px - 32px)',
						md: 'initial',
					},
				}}
			>
				<Stack
					direction={{
						xs: 'column',
						md: 'row',
					}}
					sx={{
						position: 'relative',
						alignItems: 'center',
						zIndex: 3,
						pt: {
							xs: 5,
							md: 0,
						},
						height: {
							xs: '100%',
							md: 'initial',
						},
					}}
					spacing={{
						md: 10,
					}}
				>
					<Box
						sx={{
							flexBasis: {
								xs: 'auto',
								md: '50%',
							},
						}}
					>
						<Typography variant='h1' mb={5}>
							Emergency Medicine's Job Board
						</Typography>
						<Typography variant='body1' color='text.icon' mb={5}>
							Emergency medicine practices and groups use Ivy Clinicians to
							connect with physicians, nurse practitioners, and physician
							assistants looking for jobs. Pre-verified clinicians interested in
							your practice send applications with their CVs directly to your
							inbox.
						</Typography>
						<Box>
							<Button
								variant='contained'
								color='secondary'
								size='large'
								onClick={onClickCta}
							>
								Get started
							</Button>
						</Box>
					</Box>
					<Box
						sx={{
							py: {
								xs: 5,
								md: 0,
							},
							width: '100%',
							maxWidth: '500px',
							flexBasis: {
								xs: '0',
								md: '50%',
							},
							flexGrow: {
								xs: '1',
								md: 'initial',
							},
							flexShrink: {
								xs: '1',
								md: 'initial',
							},
							overflow: {
								xs: 'hidden',
								md: 'initial',
							},
						}}
						ref={imgRef}
					>
						<Box
							sx={{
								position: 'relative',
								height: {
									xs: '100%',
									md: 'initial',
								},
								display: {
									xs: imgHeight < 275 ? 'none' : undefined,
									md: 'initial',
								},
							}}
						>
							<Box
								component='img'
								src='https://assets.ivyclinicians.io/images/for-employers-hero.png'
								sx={{
									position: 'relative',
									zIndex: 2,
									width: '100%',
									height: {
										xs: '100%',
										md: 'auto',
									},
									objectFit: 'contain',
								}}
							/>
							<SvgIcon
								width='699'
								height='571'
								viewBox='0 0 699 571'
								fill='none'
								xmlns='http://www.w3.org/2000/svg'
								sx={{
									display: {
										xs: 'none',
										md: 'initial',
									},
									zIndex: 1,
									position: 'absolute',
									width: '120%',
									height: 'auto',
									top: '-20%',
									left: '-10%',
									right: '-10%',
									bottom: '20%',
									// width: '100%',
									// height: '100%',
								}}
							>
								<path
									opacity='0.5'
									fillRule='evenodd'
									clipRule='evenodd'
									d='M315.963 61.5478C395.212 38.2092 472.193 -20.3639 548.23 7.97651C628.656 37.9525 673.404 120.779 692.41 201.856C710.448 278.805 690.162 359.138 646.608 427.635C605.023 493.037 539.851 541.341 463.411 562.004C388.768 582.181 315.13 561.879 243.435 536.618C154.979 505.451 43.6963 487.471 11.0267 402.611C-22.4134 315.749 27.4901 214.225 91.6801 142.43C145.998 81.6769 236.696 84.892 315.963 61.5478Z'
									fill='#CDEFDC'
								/>
							</SvgIcon>
						</Box>
					</Box>
				</Stack>
			</Box>
			<Box
				sx={{
					position: 'absolute',
					bottom: 32,
					left: 0,
					right: 0,
					display: 'flex',
					justifyContent: 'center',
					zIndex: 2,
				}}
			>
				<Stack
					direction='row'
					sx={{
						display: 'flex',
						justifyContent: 'space-between',
						alignItems: 'center',
						width: '100%',
						maxWidth: '1400px',
						px: 3,
						height: {
							xs: '50px',
							lg: '75px',
						},
					}}
					ref={logosRef}
				>
					{numLogos >= 3 &&
						EMPLOYER_LOGOS.slice(0, numLogos).map(({ url, opacity, alt }) => (
							<Box
								key={url}
								component='img'
								src={url}
								alt={alt}
								sx={{
									height: '100%',
									width: 'auto',
									opacity: opacity,
								}}
							/>
						))}
				</Stack>
			</Box>
			{numLogos < 3 && (
				<ScrollDownIcon
					sx={{
						position: 'absolute',
						zIndex: 4,
						bottom: 16,
						left: '50%',
						transform: 'translateX(-50%)',
						width: '63px',
						height: 'auto',
						color: 'text.icon',
						shapeRendering: 'geometricPrecision',
						opacity: scrolling ? 0 : 0.5,
						transition: 'opacity 0.3s linear',
						...(!scrolling && {
							cursor: 'pointer',
							WebkitTapHighlightColor: 'transparent',
							userSelect: 'none',
							WebkitUserSelect: 'none',
						}),
						animation: 'fade-in 2.4s, move-up-down 1.25s linear infinite',
						'@keyframes fade-in': {
							'0%': {
								opacity: 0,
							},
							'50%': {
								opacity: 0,
							},
						},
						'@keyframes move-up-down': {
							'0%, 100%': {
								bottom: 16,
							},
							'50%': {
								bottom: 28,
							},
						},
					}}
					onClick={handleClickScrollDown}
				/>
			)}
		</Box>
	);
};

export default Hero;
