import React from 'react';

import {
	Box,
	Stack,
	SvgIcon,
	type SxProps,
	type Theme,
	Typography,
} from '@mui/material';

import { combineSx } from '@ivy/lib/styling/sx';

interface QuoteProps {
	content: string;
	author: string;
	title: string;
	picture: string;
}

const Quote = ({ content, author, title, picture }: QuoteProps) => {
	return (
		<Box
			sx={{
				width: {
					md: '400px',
					lg: '541px',
				},
				maxWidth: '100%',
				p: {
					xs: 3,
					lg: 5,
				},
				display: 'flex',
				alignItems: 'center',
				borderRadius: '16px',
				boxShadow: '0px 16px 40px rgba(142, 149, 155, 0.3)',
				bgcolor: (theme) => theme.palette.background.paper,
			}}
		>
			<Box
				sx={{
					flex: '1 1 auto',
				}}
			>
				<SvgIcon
					width='32'
					height='32'
					viewBox='0 0 32 32'
					fill='none'
					xmlns='http://www.w3.org/2000/svg'
					sx={{
						fontSize: '32px',
						mb: 2,
						display: {
							xs: 'none',
							sm: 'initial',
						},
					}}
				>
					<path
						d='M28.6607 6.08824C28.6607 6.39424 28.474 6.67624 28.186 6.77891C25.746 7.64891 22 10.8482 22 16.0009C22 16.8862 22.1513 17.3342 22.4933 17.3342C22.894 17.3342 23.5293 16.6676 25.3333 16.6676C27.526 16.6676 30 18.4156 30 21.3736C30 23.9622 28.0587 26.6676 24.6667 26.6676C21.91 26.6676 18 24.6122 18 18.6056C18 10.2869 24.2647 6.75624 27.6213 5.39824C28.1167 5.19824 28.6607 5.55358 28.6607 6.08824Z'
						fill='#1EC86A'
					/>
					<path
						d='M12.6607 6.08824C12.6607 6.39424 12.474 6.67624 12.186 6.77891C9.746 7.64891 6 10.8482 6 16.0009C6 16.8862 6.15133 17.3342 6.49333 17.3342C6.894 17.3342 7.52933 16.6676 9.33333 16.6676C11.526 16.6676 14 18.4156 14 21.3736C14 23.9622 12.0587 26.6676 8.66667 26.6676C5.91 26.6676 2 24.6122 2 18.6056C2 10.2869 8.26467 6.75624 11.6213 5.39824C12.1167 5.19824 12.6607 5.55358 12.6607 6.08824Z'
						fill='#1EC86A'
					/>
				</SvgIcon>
				<Typography variant='body2' mb={2}>
					{content}
				</Typography>
				<Typography variant='body2' fontWeight='bold'>
					{author}
				</Typography>
				<Typography variant='body2' fontWeight='bold'>
					{title}
				</Typography>
			</Box>
			<Box
				component='img'
				height='104px'
				width='104px'
				src={picture}
				sx={{
					flex: '0 0 auto',
					objectFit: 'cover',
					borderRadius: '50%',
					ml: 2,
					display: {
						xs: 'none',
						sm: 'initial',
						md: 'none',
						lg: 'initial',
					},
				}}
			/>
		</Box>
	);
};

export interface HeroProps {
	sx?: SxProps<Theme>;
}

const Testimonials = ({ sx }: HeroProps) => {
	return (
		<Box
			component='section'
			sx={combineSx(
				{
					py: 10,
					position: 'relative',
					overflow: 'hidden',
				},
				sx,
			)}
		>
			<Box
				sx={{
					px: 3,
					position: 'relative',
					display: 'flex',
					flexDirection: 'column',
					justifyContent: 'center',
					alignItems: 'center',
				}}
			>
				<Box
					sx={{
						mb: 5,
					}}
				>
					<Typography
						variant='h2'
						align='center'
						mb={{
							md: 5,
						}}
					>
						Our customers love what we do
					</Typography>
					<Typography
						variant='body1'
						color='text.icon'
						align='center'
						sx={{
							display: {
								xs: 'none',
								md: 'initial',
							},
						}}
					>
						Employer groups of all sizes are using Ivy to recruiter smarter, not
						harder. See what they have to say.
					</Typography>
				</Box>
				<Box
					sx={{
						position: 'relative',
					}}
				>
					<SvgIcon
						width='640'
						height='598'
						viewBox='0 0 640 598'
						fill='none'
						xmlns='http://www.w3.org/2000/svg'
						sx={{
							height: '100%',
							width: 'auto',
							position: 'absolute',
							left: '50%',
							transform: 'translateX(-50%)',
							zIndex: 1,
							display: {
								xs: 'none',
								md: 'initial',
							},
						}}
					>
						<g opacity='0.2'>
							<path
								fillRule='evenodd'
								clipRule='evenodd'
								d='M319.946 85.9385C441.629 86.9582 579.485 114.594 616.873 230.395C654.126 345.779 561.56 453.415 459.951 519.573C375.427 574.607 270.967 565.652 186.405 510.675C82.3028 442.995 -29.9444 343.739 7.29804 225.287C45.1329 104.95 193.806 84.8814 319.946 85.9385Z'
								fill='#1EC86A'
							/>
							<path
								fillRule='evenodd'
								clipRule='evenodd'
								d='M628.677 334.213C609.854 449.944 563.312 577.172 447.547 595.789C332.2 614.338 243.276 510.394 195.18 403.925C155.17 315.357 179.023 217.192 243.784 144.728C323.511 55.5185 434.501 -36.8148 541.841 16.0297C650.889 69.7149 648.189 214.243 628.677 334.213Z'
								fill='#2682C5'
							/>
						</g>
					</SvgIcon>
					<Stack
						sx={{
							position: 'relative',
							zIndex: 2,
							py: {
								xs: 0,
								md: 12,
							},
						}}
						spacing={{
							xs: 5,
							md: 10,
							xl: 15,
						}}
						direction={{
							xs: 'column',
							md: 'row',
						}}
					>
						<Quote
							content='Getting started on Ivy was a breeze. They already had all of our facilities listed and were able to cross post our jobs without any effort on our end.'
							author='Dave Bienkowski'
							title='VP, NES+ Health'
							picture='https://assets.ivyclinicians.io/images/davebienkowski.jpg'
						/>
						<Quote
							content='I wish I had this level of customer service from our other job boards!'
							author='Katie Medlen'
							title='Talent Sourcing Manager, Vituity'
							picture='https://assets.ivyclinicians.io/images/katiemedlen.jpeg'
						/>
					</Stack>
				</Box>
			</Box>
		</Box>
	);
};

export default Testimonials;
