import {
	CmsEnrollmentProviderTypeCode,
	CmsHospitalType,
	CmsPosProviderSubtypeCode,
	TRAUMA_MAP,
} from '@ivy/constants/facility';

interface FormatFacilityInput {
	cmsHospitalType?: CmsHospitalType | null;
	posProviderSubtype?: CmsPosProviderSubtypeCode | null;
	enrollmentProviderType?: CmsEnrollmentProviderTypeCode | null;
	freestanding?: boolean | null;
	adultTraumaLvl?: number | null;
	pedTraumaLvl?: number | null;
}

export const formatFacilityType = ({
	cmsHospitalType,
	posProviderSubtype,
	enrollmentProviderType,
	freestanding,
	adultTraumaLvl,
	pedTraumaLvl,
}: FormatFacilityInput) => {
	const parts: string[] = [];
	if (
		cmsHospitalType === CmsHospitalType.REH ||
		posProviderSubtype === CmsPosProviderSubtypeCode.REH ||
		enrollmentProviderType === CmsEnrollmentProviderTypeCode.REH
	) {
		parts.push('Rural Emergency Hospital');
	} else if (
		cmsHospitalType === CmsHospitalType.CAH ||
		posProviderSubtype === CmsPosProviderSubtypeCode.CAH ||
		enrollmentProviderType === CmsEnrollmentProviderTypeCode.CAH
	) {
		parts.push('Critical Access Hospital');
	} else if (freestanding) {
		// Don't show "Freestanding" if a CAH or REH
		// All REH are implicitly freestanding, and CAH aren't freestanding
		parts.push('Freestanding');
	}
	if (adultTraumaLvl && pedTraumaLvl) {
		parts.push(
			`Level ${TRAUMA_MAP[adultTraumaLvl]} Adult & Level ${TRAUMA_MAP[pedTraumaLvl]} Pediatric Trauma Center`,
		);
	} else if (adultTraumaLvl) {
		parts.push(`Level ${TRAUMA_MAP[adultTraumaLvl]} Trauma Center`);
	} else if (pedTraumaLvl) {
		parts.push(`Level ${TRAUMA_MAP[pedTraumaLvl]} Pediatric Trauma Center`);
	}
	if (!parts.length) {
		parts.push('General ED');
	}
	return parts.join(', ');
};
