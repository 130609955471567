import React, { useState } from 'react';

import { Menu as MenuIcon, Close } from '@mui/icons-material';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import {
	Box,
	Button,
	Collapse,
	SwipeableDrawer,
	Stack,
	ListItemButton,
	type ListItemButtonProps,
	Typography,
	Divider,
	IconButton,
	type IconButtonProps,
	List,
} from '@mui/material';
import { useLocation } from 'react-router-dom';

import Badge from '@ivy/components/atoms/Badge';
import { AccountAvatar } from '@ivy/components/atoms/PlaceholderAvatar';
import RouteLink from '@ivy/components/atoms/RouteLink';
import SupportContact from '@ivy/components/molecules/SupportContact';
import { useAuthPopup } from '@ivy/components/providers/AuthPopupProvider';
import { DEGREE2PROFVERBOSE } from '@ivy/constants/clinician';
import { useCurrentAccount } from '@ivy/gql/hooks';
import { useMenuProps, type MenuNode } from '@ivy/lib/helpers/menu';
import { isMenuItemSelected } from '@ivy/lib/helpers/menu';
import useWhitelabel from '@ivy/lib/whitelabel/useWhitelabel';

interface MobileMenuItemProps extends ListItemButtonProps {
	item: MenuNode;
	onClose?: () => void;
}

const MobileMenuItem = ({ item, onClose, ...props }: MobileMenuItemProps) => {
	const location = useLocation();
	const [open, setOpen] = useState(() => isMenuItemSelected(item, location));

	return (
		<>
			<ListItemButton
				{...('route' in item &&
					!!item.route && {
						component: RouteLink,
						to: item.route,
						openInNewTab: item.openInNewTab,
					})}
				selected={isMenuItemSelected(item, location)}
				onClick={(e) => {
					if ('submenu' in item) {
						setOpen((prev) => !prev);
						return;
					}
					if ('onClick' in item && item.onClick) {
						item.onClick(e);
					}
					onClose && onClose();
				}}
				{...props}
			>
				<Typography variant='body1' sx={{ mr: 'auto' }}>
					{item.label}
				</Typography>
				{'secondary' in item && !!item.secondary && (
					<Typography
						variant='caption'
						sx={{
							ml: 3,
						}}
					>
						{item.secondary}
					</Typography>
				)}
				{'badge' in item && !!item.badge && (
					<Badge
						color='primary'
						variant='dot'
						overlap='inline'
						badgeContent={item.badge}
						rootProps={{
							sx: {
								ml: item.secondary ? 1 : 3,
							},
						}}
					/>
				)}
				{'submenu' in item &&
					(open ? (
						<ExpandLess sx={{ color: 'text.icon' }} />
					) : (
						<ExpandMore sx={{ color: 'text.icon' }} />
					))}
			</ListItemButton>
			{'submenu' in item && (
				<Collapse in={open} unmountOnExit>
					<List component='div' disablePadding>
						{item.submenu.map((el, idx) => (
							<MobileMenuItem
								item={el}
								key={`${el.label}-${idx}`}
								onClose={onClose}
								sx={{
									pl: 4,
								}}
							/>
						))}
					</List>
				</Collapse>
			)}
		</>
	);
};

export interface MobileMenuProps {
	iconButtonProps?: IconButtonProps;
	items: MenuNode[];
}

const MobileMenu = ({ items, iconButtonProps }: MobileMenuProps) => {
	const wl = useWhitelabel();
	const { openLoginPopup, openSignupPopup } = useAuthPopup();
	const { menuItems, numTotal } = useMenuProps();
	const [open, setOpen] = useState(false);
	const currAcc = useCurrentAccount();
	const menuSections = [items, menuItems].filter((el) => el.length);

	const handleClickLogIn = () => {
		setOpen(false);
		openLoginPopup();
	};

	const handleClickSignUp = () => {
		setOpen(false);
		openSignupPopup();
	};

	return (
		<>
			<IconButton onClick={() => setOpen(true)} {...iconButtonProps}>
				<Badge color='primary' badgeContent={numTotal}>
					<MenuIcon />
				</Badge>
			</IconButton>
			<SwipeableDrawer
				anchor='right'
				open={open}
				// Cannot swipe to open, but can swipe to close
				// Don't want to interfer with browser navigation or google maps
				// Also don't want swiping possible when at desktop breakpoint if on touchscreen laptop
				disableSwipeToOpen={true}
				onOpen={() => setOpen(true)}
				onClose={() => setOpen(false)}
				PaperProps={{
					sx: {
						width: '300px',
						maxWidth: '90%',
						// overflow: 'auto'
					},
				}}
			>
				<IconButton
					sx={{
						// Without this, can't close on mobile Safari
						zIndex: (theme) => theme.zIndex.drawer + 1,
						position: 'absolute',
						top: 0,
						right: 0,
					}}
					onClick={(ev) => {
						// In case the close button is over a menu item from scrolling
						ev.stopPropagation();
						setOpen(false);
					}}
				>
					<Close />
				</IconButton>
				{/* Scrolling container so that the close button always remains on upper right hand corner */}
				<Stack height='100%' overflow='auto' pt={1} component='nav'>
					{currAcc ? (
						<>
							<Stack alignItems='center' py={2}>
								<AccountAvatar
									account={currAcc}
									size={80}
									sx={{
										mb: 1,
									}}
									TypographyProps={{
										variant: 'h4',
									}}
								/>
								<Typography
									variant='subtitle1'
									component='p'
									noWrap
									maxWidth='100%'
									gutterBottom
								>
									{currAcc.pi?.fullName}
								</Typography>
								<Typography
									variant='subtitle2'
									component='p'
									noWrap
									color='text.secondary'
								>
									{currAcc.isClinician
										? DEGREE2PROFVERBOSE[currAcc.clinician?.profDegree]
										: currAcc.isOrgUser
										? currAcc.orgUser?.org?.name
										: ''}
								</Typography>
							</Stack>
							<Divider />
						</>
					) : (
						<>
							{/* Chrome or MUI is messing things up if re-use an svg. */}
							{/* E.g. if you set display: none on one, it applies to all */}
							{/* In this case, the gradient messes up when displaying the same logo twice */}
							{/* So we pull it in from a different source */}
							<Stack px={2} pt={2}>
								<RouteLink
									to={RouteLink.routes.ROOT}
									sx={{
										ml: 'auto',
										mr: 'auto',
										mb: 1,
									}}
								>
									<Box
										component='img'
										src={wl.logoVertical || wl.logo}
										sx={{
											width: '100%',
											height: 'auto',
											maxHeight: '100px',
											mb: 1,
											mx: 'auto',
										}}
									/>
								</RouteLink>
								<Stack mt={2} spacing={1}>
									<Button
										variant='contained'
										fullWidth
										color='secondary'
										{...(wl.menu.fullSso
											? {
													component: RouteLink,
													to: wl.menu.fullSso,
											  }
											: {
													onClick: handleClickSignUp,
											  })}
									>
										Sign up
									</Button>
									<Button
										variant='outlined'
										fullWidth
										color='primary'
										{...(wl.menu.fullSso
											? {
													component: RouteLink,
													to: wl.menu.fullSso,
											  }
											: {
													onClick: handleClickLogIn,
											  })}
									>
										Log in
									</Button>
								</Stack>
							</Stack>
							<Divider
								variant='middle'
								sx={{
									my: 2,
								}}
							/>
						</>
					)}
					<Stack>
						{menuSections.map((section, sectionIdx, self) => (
							<Box key={`menu-section-${sectionIdx}`}>
								{section.map((item, itemIdx) =>
									item ? (
										<MobileMenuItem
											item={item}
											key={`${item.label}-${itemIdx}`}
										/>
									) : (
										<Divider key={`mobile-menu-divider-${itemIdx}`} />
									),
								)}
								{sectionIdx !== self.length - 1 && (
									<Divider key={`menu-section-divider-${sectionIdx}`} />
								)}
							</Box>
						))}
					</Stack>
					<Box
						sx={{
							mt: 'auto',
							mb: 2,
							display: currAcc ? 'none' : undefined,
						}}
					>
						<SupportContact
							troubleText='Have questions?'
							contactText='Contact us'
							variant='caption'
							sx={{
								// This sets a minimum margin
								mt: 5,
							}}
						/>
					</Box>
				</Stack>
			</SwipeableDrawer>
		</>
	);
};

export default MobileMenu;
