import React from 'react';

import RouteLink from '@ivy/components/atoms/RouteLink';
import MapCard, {
	type MapCardDataObject,
	type MapCardProps,
} from '@ivy/components/organisms/MapCard';
import { useRedirect } from '@ivy/components/providers/RedirectProvider';
import {
	ED_VOLUME_RANGE,
	ED_VOLUME_VERBOSE,
	FACILITY_PLACEHOLDER_IMAGE,
} from '@ivy/constants/facility';
import { gql, getFragmentData, type FragmentType } from '@ivy/gql/types';
import { formatInteger } from '@ivy/lib/formatting/number';
import { buildInternalLink } from '@ivy/lib/util/route';
import { type WhitelabelConfigName } from '@ivy/lib/whitelabel/whitelabelConfig';

const FacilityCard_FacilityFDoc = gql(/* GraphQL */ `
	fragment FacilityCard_Facility on facility {
		id
		name
		slug
		picture {
			publicUrl: public_url
		}
		adultTraumaLvl: adult_trauma_lvl
		pedTraumaLvl: ped_trauma_lvl
		freestandingEr: freestanding_er
		facilityType @client
		primaryResidencies: primary_residencies {
			id
			training {
				id
				name
			}
		}
		location
		city
		state
		cmsFacilityPos: cms_facility_pos {
			id
			providerSubtype: provider_subtype
		}
		cmsFacilityEnrollment: cms_facility_enrollment {
			id
			providerTypeCode: provider_type_code
		}
		cmsFacility: cms_facility {
			id
			hospitalType: hospital_type
			metrics(where: { measure_id: { _eq: "EDV" } }) {
				id
				measureId: measure_id
				score
			}
			facilities(order_by: [{ name: asc }]) {
				id
				name
			}
		}
		ahaFacility: aha_facility_22 {
			id: ID
			units {
				id: ID
				name: UNAME
				par: parent_aha_facility {
					id: ID
					name: MNAME
				}
			}
			rollups @client
		}
		maxRatePosting: max_postings_for_prof(args: { prof: $profession }) {
			id
			slug
			title
			hasRate: has_rate
			ratePeriod: rate_period
			isExactRate: is_exact_rate
			exactRate: exact_rate
			minRate: min_rate
			maxRate: max_rate
			normRate: normalized_rate
			wageTrunc @client
		}
		numPostings: num_postings_for_prof(args: { prof: $profession })
		featured: featured_for_prof(args: { prof: $profession })
		immediateNeeds(args: { prof: $profession }) @client
		contracts(where: { active: { _eq: true } }, order_by: { created_at: asc }) {
			id
			org {
				id
				name
			}
		}
		reviewAgg: reviews_aggregate(
			where: {
				active: { _eq: true }
				visible: { _eq: true }
				has_active_contract: { _eq: true }
			}
		) {
			aggregate {
				avg {
					rating: rating_job
				}
				count
			}
		}
	}
`);

export interface FacilityCardProps extends Omit<MapCardProps, 'entity'> {
	facility: FragmentType<typeof FacilityCard_FacilityFDoc>;
	defaultTo?: WhitelabelConfigName;
	openInNewTab?: boolean;
	backNavTarget?: string;
	hideJobPosting?: boolean;
	hideBadge?: boolean;
}

const FacilityCard = ({
	facility: rawFacility,
	openInNewTab = false,
	defaultTo,
	backNavTarget,
	hideJobPosting,
	hideBadge,
	...props
}: FacilityCardProps) => {
	const facility = getFragmentData(FacilityCard_FacilityFDoc, rawFacility);
	const featuredPosting = facility.maxRatePosting![0];
	const pathname = buildInternalLink(RouteLink.routes.FACILITY_SHOW, {
		facilityId: [facility.id, facility.slug],
	});
	const redirect = useRedirect();

	const facilityObject: MapCardDataObject = {
		stats: [],
	};
	const _target = backNavTarget
		? { backNav: { target: backNavTarget } }
		: undefined;

	facilityObject.stats = [
		{
			label: 'Facility Type:',
			value: facility.facilityType,
		},
		{
			label: 'EM Residency:',
			value:
				facility.primaryResidencies?.map((el) => el.training.name).join(', ') ||
				'None',
		},
	];

	const edv = facility.cmsFacility?.metrics.find((el) => el.measureId === 'EDV')
		?.score;
	const numEntities =
		(facility.cmsFacility?.facilities.length ?? 0) > 1
			? ` across ${formatInteger(
					facility.cmsFacility!.facilities.length,
			  )} sites`
			: '';
	if (edv) {
		facilityObject.stats.push({
			label: 'Annual ED Visits:',
			value: `${ED_VOLUME_VERBOSE[edv]} (${ED_VOLUME_RANGE[edv]})${numEntities}`,
		});
	}

	if (!hideJobPosting && featuredPosting) {
		facilityObject.stats.push({
			label: 'Job Posting:',
			value: featuredPosting.title,
			isLink: true,
			linkProps: {
				to: {
					pathname: buildInternalLink(RouteLink.routes.JOB_POSTING_SHOW, {
						postingId: [featuredPosting.id, featuredPosting.slug],
					}),
					whitelabel: defaultTo,
				},
				// This will actually only be useful on mobile where we don't open in a new tab
				// On desktop, where we open in a new tab, this info is lost, so we set a default backNav
				state: _target,
				openInNewTab: true,
			},
			chipLabel:
				facility.numPostings && facility.numPostings > 1
					? `+${facility.numPostings - 1}`
					: undefined,
		});
	}

	return (
		<MapCard
			{...props}
			entity={{
				...facilityObject,
				title: facility.name,
				locationInfo: `${facility.city}, ${facility.state}`,
				picture: facility.picture?.publicUrl || FACILITY_PLACEHOLDER_IMAGE,
				businessInfo: facility.contracts.map((el) => el.org.name).join(', '),
				rating:
					(facility!.reviewAgg!.aggregate!.avg?.rating || 0) > 0
						? facility.reviewAgg.aggregate?.avg?.rating?.toFixed(1)
						: undefined,
				badgeText:
					!hideBadge && facility.immediateNeeds ? 'Immediate Needs' : undefined,
				badgeRate:
					!hideBadge && featuredPosting?.hasRate
						? featuredPosting.wageTrunc
						: undefined,
				linkProps: {
					to: {
						pathname,
						whitelabel: defaultTo,
					},
					openInNewTab,
					state: _target,
				},
			}}
			openInNewTab={openInNewTab}
			onClick={() => {
				redirect(
					{
						pathname,
						whitelabel: defaultTo,
					},
					{
						openInNewTab,
						state: _target,
					},
				);
			}}
		/>
	);
};

export default FacilityCard;
