import React, { useEffect, useMemo, useState } from 'react';

import { Phone } from '@mui/icons-material';
import {
	useTheme,
	Tabs,
	Tab,
	Stack,
	Typography,
	Avatar,
	Box,
	Container,
	type SxProps,
	type Theme,
} from '@mui/material';
import mergeWith from 'lodash/mergeWith';
import {
	Routes,
	Route,
	Navigate,
	useResolvedPath,
	useLocation,
	matchRoutes,
} from 'react-router-dom';

import RouteLink from '@ivy/components/atoms/RouteLink';
import { BriefcaseIcon, LocationIcon } from '@ivy/components/icons';
import { type BaseTemplateProps } from '@ivy/components/templates/BaseTemplate';
import NavTemplate, {
	type NavTemplateProps,
} from '@ivy/components/templates/NavTemplate';
import { combineSx } from '@ivy/lib/styling/sx';

export enum IconTypes {
	WORK = 'work',
	LOCATION = 'location',
	PHONE = 'phone',
}

export interface InfoSnippetProps {
	icon?: IconTypes | JSX.Element;
	text?: React.ReactNode;
}

const InfoSnippet = ({ icon, text }: InfoSnippetProps) => {
	let iconComp: React.ReactNode | null | undefined = null;

	if (icon) {
		switch (icon) {
			case IconTypes.WORK:
				iconComp = <BriefcaseIcon />;
				break;
			case IconTypes.LOCATION:
				iconComp = <LocationIcon />;
				break;
			case IconTypes.PHONE:
				iconComp = <Phone />;
				break;
			default:
				iconComp = icon;
				break;
		}
	}

	return (
		<Typography
			variant='body2'
			color='text.icon'
			display='flex'
			sx={{
				alignItems: 'center',
				svg: {
					color: 'text.primary',
					fontSize: '14px',
					mb: '-0.1em',
					mr: 1,
				},
			}}
		>
			{iconComp}
			{text}
		</Typography>
	);
};

export interface PageTabProps {
	path: string;
	link?: string;
	element: React.ReactNode;
	altPaths?: string[];
	label: string;
	labelContent?: React.ReactNode;
	index?: boolean;
	action?: React.ReactNode;
	hideInfoArray?: boolean;
	NavTemplateProps: Omit<
		NavTemplateProps<never>,
		'DataLoaderProps' | 'children'
	>;
	hidden?: boolean;
}

export type TabTemplateProps<T> = BaseTemplateProps<T> &
	Pick<NavTemplateProps<T>, 'defaultBackNav'> & {
		title: React.ReactNode;
		image?: string;
		infoArray?: InfoSnippetProps[];
		primaryAction?: React.ReactNode;
		actionSx?: SxProps<Theme>;
		middleInfo?: React.ReactNode;
		tabs?: PageTabProps[];
	};

const getCurrentTabIndex = (
	root: string,
	pathname: string,
	tabs: PageTabProps[],
) => {
	for (let i = 0; i < tabs.length; i++) {
		const tab = tabs[i];
		const possibleMatches = [tab.path, ...(tab.altPaths || [])];
		if (
			matchRoutes(
				possibleMatches.map((el) => ({
					path: `${root}${el ? '/' : ''}${el}`,
				})),
				pathname,
			)?.length
		) {
			return i;
		}
	}

	return -1; // Return -1 if no matching subpath is found
};

const TabTemplate = <T,>({
	title,
	image,
	infoArray,
	primaryAction,
	actionSx,
	middleInfo,
	children,
	tabs = [],
	...props
}: TabTemplateProps<T>) => {
	const hasTabs = !!tabs.length;
	const location = useLocation();
	const theme = useTheme();
	const resolvedPath = useResolvedPath('');
	const [currentTabIndex, setCurrentTabIndex] = useState(() => {
		return hasTabs
			? getCurrentTabIndex(resolvedPath.pathname, location.pathname, tabs)
			: 0;
	});
	const currTab: PageTabProps | undefined = tabs[currentTabIndex];

	useEffect(() => {
		if (!hasTabs) {
			return;
		}
		const newPathIndex = getCurrentTabIndex(
			resolvedPath.pathname,
			location.pathname,
			tabs,
		);
		if (newPathIndex !== currentTabIndex) setCurrentTabIndex(newPathIndex);
	}, [
		hasTabs,
		resolvedPath.pathname,
		location.pathname,
		tabs,
		currentTabIndex,
		setCurrentTabIndex,
	]);

	const renderRoutes = (tabArr: PageTabProps[]) => {
		return (
			<Routes>
				{tabArr.map((item) =>
					item.index ? (
						<React.Fragment key={item.path}>
							<Route path={item.path} element={item.element} />
							<Route
								index
								path='*'
								element={
									<Navigate
										replace
										to={{
											pathname: `${resolvedPath.pathname}${
												item.path ? '/' : ''
											}${item.path}`,
											search: location.search,
										}}
									/>
								}
							/>
						</React.Fragment>
					) : (
						<React.Fragment key={item.path}>
							<Route path={item.path} element={item.element} />
							{item.altPaths?.map((path) => (
								<Route key={path} path={path} element={item.element} />
							))}
						</React.Fragment>
					),
				)}
			</Routes>
		);
	};

	const NavTemplateProps = useMemo(() => {
		const baseProps = mergeWith(
			{
				TopBarProps: {
					sx: {
						borderStyle: 'none',
					},
				},
				showFooter: true,
				hideBackNav: false,
				backNavSx: {
					bgcolor: theme.palette.light4.main,
					pt: 0,
					pb: 1,
				},
				containerSx: {
					display: 'flex',
					flexDirection: 'column',
				},
				maxWidth: 'xl',
				...props,
			},
			currTab?.NavTemplateProps,
			(objValue, srcValue, key) => {
				if (
					key === 'pageTitle' &&
					Array.isArray(objValue) &&
					Array.isArray(srcValue)
				) {
					return [...srcValue, ...objValue];
				}
				// Return undefined for default handling
			},
		);
		return baseProps;
	}, [theme, props, currTab]);

	const action = currTab?.action || primaryAction;

	return (
		<NavTemplate {...NavTemplateProps}>
			<Box
				component='section'
				sx={{
					flex: '0 0 auto',
					bgcolor: theme.palette.light4.main,
					pt: {
						xs: 3,
						mobileMenu: 0,
					},
					pb: !hasTabs || tabs.length === 1 ? 3 : 0,
					// Full bleed
					width: '100vw',
					position: 'relative',
					left: '50%',
					right: '50%',
					ml: '-50vw',
					mr: '-50vw',
				}}
			>
				<Container maxWidth={NavTemplateProps.fullscreen ? false : 'xl'}>
					<Stack
						direction={{ md: 'row', xs: 'column' }}
						justifyContent='space-between'
						alignItems={{ md: 'center', xs: 'inherit' }}
						spacing={3}
					>
						<Stack
							direction={{
								sm: 'row',
								xs: 'column',
							}}
							spacing={{
								xs: 0,
								sm: 2,
							}}
							// flex='1 1 auto'
						>
							{!!image && (
								<Avatar
									src={image}
									sx={{ display: { sm: 'block', xs: 'none' } }}
								/>
							)}
							<Stack spacing={1}>
								<Typography
									variant='h4'
									component='h1'
									sx={{
										display: 'flex',
										flexFlow: 'row nowrap',
										alignItems: 'center',
									}}
								>
									{!!image && (
										<Avatar
											src={image}
											sx={{ display: { sm: 'none', xs: 'block' }, mr: 1 }}
										/>
									)}
									{title}
								</Typography>
								{!currTab?.hideInfoArray &&
									infoArray
										?.filter((info) => info.text !== null)
										.map((info, index) => (
											<InfoSnippet key={index} {...info} />
										))}
							</Stack>
						</Stack>
						<Stack sx={{ display: { lg: 'flex', xs: 'none' } }}>
							{middleInfo}
						</Stack>
						<Stack
							spacing={2}
							sx={combineSx(
								{
									alignItems: 'center',
									flexDirection: 'row',
									flex: '0 0 auto',
								},
								actionSx,
							)}
						>
							{action}
						</Stack>
					</Stack>
					{hasTabs && tabs.length > 1 && (
						<Tabs
							value={currentTabIndex}
							sx={{ mt: 1 }}
							variant='scrollable'
							scrollButtons
							allowScrollButtonsMobile
						>
							{tabs.map((item) => (
								<Tab
									component={RouteLink}
									to={`${resolvedPath.pathname}${
										item.link || item.path ? '/' : ''
									}${item.link || item.path}`}
									key={item.label}
									label={
										item.labelContent ? (
											<Typography
												component='div'
												display='flex'
												alignItems='center'
												variant='inherit'
											>
												{item.label}
												{item.labelContent}
											</Typography>
										) : (
											item.label
										)
									}
									sx={{
										display: item.hidden ? 'none' : undefined,
									}}
								/>
							))}
						</Tabs>
					)}
				</Container>
			</Box>
			<Box sx={{ flex: '1 1 auto' }}>
				{hasTabs && renderRoutes(tabs)}
				{children}
			</Box>
		</NavTemplate>
	);
};

export default TabTemplate;
