import React, { useState, useMemo } from 'react';

import {
	HelpOutline,
	Info,
	InfoOutlined,
	OpenInNew,
} from '@mui/icons-material';
import {
	Box,
	type BoxProps,
	Grid,
	Typography,
	type Theme,
	type SxProps,
	type ButtonProps,
	Button,
	Stack,
} from '@mui/material';

import RouteLink from '@ivy/components/atoms/RouteLink';
import Tooltip from '@ivy/components/atoms/Tooltip';
import BlobIconWrapper from '@ivy/components/molecules/BlobIconWrapper';
import LabeledTypography, {
	type LabeledTypographyProps,
} from '@ivy/components/molecules/LabeledTypography';
import Popup, { type PopupProps } from '@ivy/components/molecules/Popup';
import { type ApplyToJobButtonProps } from '@ivy/components/organisms/ApplyToJobButton';
import BannerAd from '@ivy/components/organisms/BannerAd';
import { useCurrentAccount } from '@ivy/gql/hooks';
import { gql, getFragmentData, type FragmentType } from '@ivy/gql/types';
import { type FacilityFeatures_FacilityFragment } from '@ivy/gql/types/graphql';
import { oxfordJoin } from '@ivy/lib/formatting/string';
import ReportCorrection from '@ivy/views/facility/FacilityShow/FacilityProfile/ReportCorrection';

import {
	type FacilitySectionConfig,
	type FacilitySectionItem,
	type FacilityFieldSource,
	getFacilityFields,
} from './facilityFields';

const UNKNOWN = 'Unknown';

interface SourcePopupProps extends PopupProps {
	sources: FacilityFieldSource[];
	onClickReport: ButtonProps['onClick'];
}

const SourcePopup = ({
	sources,
	onClickReport,
	onClose,
	...props
}: SourcePopupProps) => {
	return (
		<Popup
			shrink
			onClose={onClose}
			contentSx={(theme) => ({
				[theme.breakpoints.up('sm')]: {
					pt: 5,
					pb: 10,
					px: 10,
				},
				[theme.breakpoints.down('sm')]: {
					pb: 5,
					px: 5,
				},
			})}
			{...props}
		>
			<Typography variant='body1' mb={3}>
				This data is sourced directly from{' '}
				<b>
					{oxfordJoin(
						sources.map(
							(el) =>
								`${el.fullName} (${
									typeof el.year === 'number' ? el.year : el.year()
								})`,
						),
					)}
				</b>
				.
			</Typography>
			<Typography variant='body1' mb={5}>
				While we strive to ensure accuracy, discrepancies may occur. If you
				notice any incorrect or outdated information, please report it to us via
				the button below. Your feedback helps us maintain the most accurate and
				up-to-date resource possible.
			</Typography>
			<Stack
				direction={{
					xs: 'column',
					sm: 'row',
				}}
				spacing={2}
			>
				<Button
					variant='contained'
					onClick={onClickReport}
					sx={{
						flexBasis: '50%',
						p: 2,
					}}
					size='large'
				>
					Report error
				</Button>
				<Button
					variant='outlined'
					onClick={onClose}
					sx={{
						flexBasis: '50%',
						p: 2,
					}}
					size='large'
				>
					Close
				</Button>
			</Stack>
		</Popup>
	);
};

interface FeatureProps
	extends Omit<LabeledTypographyProps, 'label' | 'text'>,
		FacilitySectionItem {
	facility: FacilityFeatures_FacilityFragment;
}

const Feature = ({
	facility,
	label,
	getValue,
	isFeatured = () => true,
	customIcon,
	formatter = (val) => val,
	link,
	sources,
	...props
}: FeatureProps) => {
	const value = getValue(facility);
	const featured = isFeatured(value);
	const [sourcePopupOpen, setSourcePopupOpen] = useState(false);
	const [reportPopupOpen, setReportPopupOpen] = useState(false);

	const handleClickSource = () => {
		setSourcePopupOpen(true);
	};

	const handleCloseSource = () => {
		setSourcePopupOpen(false);
	};

	const handleClickReport = () => {
		setSourcePopupOpen(false);
		setReportPopupOpen(true);
	};

	const handleCloseReport = () => {
		setReportPopupOpen(false);
	};

	return (
		<>
			<LabeledTypography
				icon={<BlobIconWrapper icon={customIcon} />}
				label={
					link ? (
						<RouteLink openInNewTab to={link}>
							{label}
							<OpenInNew
								sx={{
									fontSize: 'inherit',
									ml: 0.25,
									verticalAlign: 'text-top',
								}}
							/>
						</RouteLink>
					) : (
						label
					)
				}
				labelProps={{
					mb: 0.5,
				}}
				text={featured !== null ? formatter(value, facility) : UNKNOWN}
				disabled={featured === null}
				textProps={{
					fontWeight: 'bold',
					whiteSpace: 'pre-wrap',
				}}
				footer={
					featured !== null ? (
						<Box
							sx={{
								cursor: 'pointer',
								textDecoration: 'none',
							}}
							onClick={handleClickSource}
						>
							<Box
								component='span'
								sx={{
									verticalAlign: 'middle',
								}}
							>
								Source:{' '}
								{sources
									.map(
										(el) =>
											`${el.name} ${
												typeof el.year === 'number' ? el.year : el.year()
											}`,
									)
									.join(', ')}
							</Box>
							<InfoOutlined
								sx={{
									ml: 0.5,
									fontSize: '1.3em',
									verticalAlign: 'middle',
								}}
							/>
						</Box>
					) : (
						'Source: N/A'
					)
				}
				{...props}
			/>
			{sourcePopupOpen && (
				<SourcePopup
					open
					sources={sources}
					onClose={handleCloseSource}
					onClickReport={handleClickReport}
				/>
			)}
			{reportPopupOpen && (
				<ReportCorrection
					open
					onClose={handleCloseReport}
					facility={facility}
					defaultSelection='facility'
				/>
			)}
		</>
	);
};

interface FeatureSetProps extends FacilitySectionConfig {
	facility: FacilityFeatures_FacilityFragment;
	sx?: SxProps<Theme>;
	showDisclaimer?: boolean;
}

const FeatureSet = React.forwardRef<HTMLDivElement, FeatureSetProps>(
	({ facility, section, tooltip, items, sx, showDisclaimer = false }, ref) => {
		const display = items.some(
			(el) => !el.isFeatured || el.isFeatured(el.getValue(facility)),
		);

		return (
			<Box sx={sx} ref={ref}>
				<Box
					sx={{
						display: 'flex',
						alignItems: 'center',
					}}
				>
					<Typography
						component='h2'
						variant='h5'
						sx={{
							opacity: !display ? 0.4 : undefined,
						}}
					>
						{section} {!display && '(Not Available)'}
					</Typography>
					{!!tooltip && (
						<Tooltip title={tooltip}>
							<HelpOutline
								fontSize='small'
								sx={{
									ml: 1,
								}}
							/>
						</Tooltip>
					)}
				</Box>
				{showDisclaimer && !!facility.ahaFacility?.units?.length && (
					<Typography
						variant='body2'
						sx={{
							p: 2,
							color: 'text.icon',
							bgcolor: 'light4.main',
							borderRadius: (theme) => `${theme.shape.borderRadius}px`,
							width: 'fit-content',
							maxWidth: '100%',
							mt: {
								xs: 3,
								md: 5,
							},
						}}
					>
						<Info
							fontSize='small'
							sx={{
								position: 'relative',
								top: '5px',
							}}
						/>{' '}
						Data for this facility includes information for{' '}
						{oxfordJoin(facility.ahaFacility.rollups)}.
					</Typography>
				)}
				{display && (
					// Note there is a negative 4 margin here, so we assign it to 1 instead
					<Grid container spacing={4} mt={1}>
						{items.map((item) => (
							<Grid item key={item.label} xs={12} md={6} lg={4}>
								<Feature facility={facility} {...item} />
							</Grid>
						))}
					</Grid>
				)}
			</Box>
		);
	},
);

const FacilityFeatures_FacilityFDoc = gql(/* GraphQL */ `
	fragment FacilityFeatures_Facility on facility {
		id
		name
		state
		adultTraumaLvl: adult_trauma_lvl
		pedTraumaLvl: ped_trauma_lvl
		freestandingEr: freestanding_er
		primaryResidencies: primary_residencies {
			id
			training {
				id
				name
				slug
			}
		}
		strokeCerts: stroke_certs {
			id
			level
		}
		ehrs {
			id
			name
		}
		cmsFacility: cms_facility {
			id
			overallRating: overall_rating
			metrics(
				where: {
					measure_id: {
						_in: [
							"OP_18b"
							"OP_22"
							"SEP_1"
							"MORT_30_AMI"
							"MORT_30_COPD"
							"MORT_30_STK"
							"EDV"
						]
					}
				}
			) {
				id
				measureId: measure_id
				score
			}
			hospitalType: hospital_type
			facilities(order_by: [{ name: asc }]) {
				id
				name
			}
		}
		cmsFacilityPos: cms_facility_pos {
			id
			providerSubtype: provider_subtype
		}
		cmsFacilityEnrollment: cms_facility_enrollment {
			id
			providerTypeCode: provider_type_code
		}
		ahaFacility: aha_facility_22 {
			id: ID
			medSchool: MAPP5
			healthSystem: SYSNAME
			svc1 {
				id: ID
				cathLab: ICLABHOS
				hospBeds: HOSPBD
				genBeds: GENBD
				genPedBeds: PEDBD
				msicBeds: MSICBD
				pedicBeds: PEDICBD
				burnBeds: BRNBD
				psychBeds: PSYBD
			}
			svc2 {
				id: ID
				psychOutpatientServices: PSYOPHOS
				pscyhEmergencyServices: PSYEMHOS
				psychPedBeds: PSCBD
				psychGerBeds: PSGBD
			}
			units {
				id: ID
				name: UNAME
				par: parent_aha_facility {
					id: ID
					name: MNAME
				}
			}
			rollups @client
		}
		facilityType @client
		contracts(where: { active: { _eq: true } }, order_by: { created_at: asc }) {
			id
			org {
				id
				ownershipStructure: ownership_structure
			}
		}
		accreditations {
			id
			accreditation
			level
		}
		...ReportCorrection_Facility
	}
`);

export interface FacilityFeaturesProps extends BoxProps {
	facility: FragmentType<typeof FacilityFeatures_FacilityFDoc>;
	onClickShowEmployers: ApplyToJobButtonProps['onClickShowEmployers'];
}

const FacilityFeatures = ({
	facility: rawFacility,
	onClickShowEmployers,
	...props
}: FacilityFeaturesProps) => {
	const currAcc = useCurrentAccount();
	const facility = getFragmentData(FacilityFeatures_FacilityFDoc, rawFacility);

	const facilityFields = useMemo(() => {
		return getFacilityFields({ onClickShowEmployers });
	}, [onClickShowEmployers]);

	return (
		<Box component='section' {...props}>
			{facilityFields.map((featureSet, idx) => (
				<React.Fragment key={featureSet.section}>
					<FeatureSet
						facility={facility}
						{...featureSet}
						showDisclaimer={!idx}
						sx={
							idx
								? {
										mt: {
											xs: 6,
											md: 10,
										},
								  }
								: undefined
						}
					/>
					{!idx && (
						<BannerAd
							slotId={'facility-view'}
							bp={'sm'}
							category={{
								disjunctions: [
									['SPECIALTY_EM', 'SPECIALTY_ALL'],
									currAcc?.clinician?.profession
										? [
												`PROFESSION_${currAcc.clinician.profession}`,
												'PROFESSION_ALL',
										  ]
										: ['PROFESSION_ALL'],
									[`LOCATION_${facility.state}`, 'LOCATION_ALL'],
								],
							}}
							sx={{
								mt: {
									xs: 6,
									md: 10,
								},
							}}
						/>
					)}
				</React.Fragment>
			))}
		</Box>
	);
};

export default FacilityFeatures;
