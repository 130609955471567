import React, { useState, useRef } from 'react';

import { Menu as MenuIcon } from '@mui/icons-material';
import {
	Button,
	Divider,
	Menu,
	MenuItem,
	Typography,
	useMediaQuery,
	useTheme,
} from '@mui/material';
import { useLocation } from 'react-router-dom';

import Badge from '@ivy/components/atoms/Badge';
import { AccountAvatar } from '@ivy/components/atoms/PlaceholderAvatar';
import RouteLink from '@ivy/components/atoms/RouteLink';
import { useCurrentAccount } from '@ivy/gql/hooks';
import { useMenuProps, isMenuItemSelected } from '@ivy/lib/helpers/menu';

const AuthAvatar = () => {
	const theme = useTheme();
	const isXs = useMediaQuery(theme.breakpoints.down('sm'), { noSsr: true });
	const location = useLocation();
	// Need to maintain a ref rather than setting anchor using event.currentTarget b/c diff buttons render depending
	// on the breakpoint
	const anchorRef = useRef();
	const [open, setOpen] = useState(false);
	const currAcc = useCurrentAccount();
	const { menuItems, numTotal } = useMenuProps();

	const handleClick = (ev) => {
		ev.preventDefault();
		ev.stopPropagation();
		setOpen(true);
	};

	return (
		<>
			<Button
				variant='outlined'
				endIcon={<MenuIcon />}
				onClick={handleClick}
				color='dark1'
				sx={{
					borderColor: 'divider',
					color: 'dark1.main',
					bgcolor: 'background.paper',
					'&:hover': {
						bgcolor: 'light2.main',
					},
				}}
				ref={anchorRef}
			>
				<Badge color='primary' badgeContent={numTotal} overlap='circular'>
					<AccountAvatar
						account={currAcc}
						sx={{
							ml: '-10px',
						}}
					/>
				</Badge>
			</Button>
			<Menu
				// Auto focus is disabled because then browsers does a onHover to the first menu item, and even the
				// logout button appears as a link to the first menu item when it's actually not
				// Unfortunately, this is not great for accessibility
				autoFocus={false}
				open={open}
				onClose={() => setOpen(false)}
				// Ensure visibility of anchor
				anchorEl={anchorRef.current}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'center',
				}}
				transformOrigin={{
					vertical: 'top',
					horizontal: 'center',
				}}
			>
				{menuItems.map((item, idx) =>
					item ? (
						<MenuItem
							dense={isXs}
							component={item.route ? RouteLink : item.component}
							to={item.route}
							key={`avatar-menu-item-${item.label}`}
							selected={isMenuItemSelected(item, location)}
							onClick={(e) => {
								item.onClick && item.onClick(e);
								setOpen(false);
							}}
						>
							<Typography variant='body1' sx={{ mr: 'auto' }}>
								{item.label}
							</Typography>
							{!!item.secondary && (
								<Typography
									variant='caption'
									sx={{
										ml: 3,
									}}
								>
									{item.secondary}
								</Typography>
							)}
							{!!item.badge && (
								<Badge
									color='primary'
									variant='dot'
									overlap='inline'
									badgeContent={item.badge}
									rootProps={{
										sx: { ml: item.secondary ? 1 : 3 },
									}}
								/>
							)}
						</MenuItem>
					) : (
						<Divider key={`menu-item-divider-${idx}`} />
					),
				)}
			</Menu>
		</>
	);
};

export default AuthAvatar;
