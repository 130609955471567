import React, { useMemo } from 'react';

import { KeyboardBackspace } from '@mui/icons-material';
import {
	Box,
	Container,
	GlobalStyles,
	Stack,
	type ContainerProps,
	type SxProps,
	type Theme,
} from '@mui/material';
import isEmpty from 'lodash/isEmpty';
import merge from 'lodash/merge';
import { useLocation } from 'react-router-dom';
import { useElementSize } from 'usehooks-ts';

import BackNavButton from '@ivy/components/atoms/BackNavButton';
import TopBar, {
	type TopBarProps,
} from '@ivy/components/organisms/TopBar/TopBar';
import BaseTemplate, {
	type BaseTemplateProps,
} from '@ivy/components/templates/BaseTemplate';
import { use100vh } from '@ivy/lib/hooks';
import { combineSx } from '@ivy/lib/styling/sx';
import useWhitelabel from '@ivy/lib/whitelabel/useWhitelabel';

export interface NavTemplateProps<T> extends BaseTemplateProps<T> {
	defaultBackNav?: TopBarProps['backNav'];
	hideBackNav?: boolean;
	maxWidth?: ContainerProps['maxWidth'];
	backgroundColor?: string;
	TopBarProps?: TopBarProps;
	containerSx?: SxProps<Theme>;
	fullscreen?: boolean;
	showFooter?: boolean;
	children: React.ReactNode;
	backNavSx?: SxProps<Theme>;
}

const NavTemplate = <T,>({
	defaultBackNav,
	hideBackNav,
	children,
	maxWidth = 'xl',
	backgroundColor,
	TopBarProps,
	containerSx,
	fullscreen = false,
	showFooter = false,
	pageTitle,
	pageNoIndex,
	pageDescription,
	globalStyles,
	backNavSx,
	...props
}: NavTemplateProps<T>) => {
	const vh = use100vh();
	// Unreliable
	// const [appbarRef, { height: appbarHeight }] = useElementSize();
	const [backNavRef, { height: backNavHeight }] = useElementSize();
	const location = useLocation();
	let backNav = location.state?.['backNav'];
	if (!backNav || !backNav.link) {
		backNav = defaultBackNav;
	}
	const whitelabel = useWhitelabel();

	const appliedGlobalStyles = useMemo(() => {
		const styles = merge(
			backgroundColor
				? {
						body: {
							backgroundColor,
						},
				  }
				: {},
			globalStyles || {},
		);
		return isEmpty(styles) ? undefined : styles;
	}, [backgroundColor, globalStyles]);

	// Only show backnav if (a) we're fullscreen and hideBackNav is explicitly set to false, or
	// (b) we're not fullscreen and hideBackNav is false or undefined
	const showBackNav =
		!!backNav && (fullscreen ? hideBackNav === false : !hideBackNav);

	return (
		<BaseTemplate
			pageTitle={pageTitle}
			pageNoIndex={pageNoIndex}
			pageDescription={pageDescription}
			globalStyles={appliedGlobalStyles}
			{...props}
		>
			{!!backgroundColor && (
				<GlobalStyles
					styles={{
						body: {
							backgroundColor,
						},
					}}
				/>
			)}
			<Stack
				sx={{
					minHeight: vh,
				}}
			>
				<TopBar backNav={backNav} {...TopBarProps} />
				<Box
					sx={{
						mt: {
							xs: '64px',
							mobileMenu: '104px',
						},
						// Will flex stretch to full height
						flex: '1 1',
						display: 'flex',
						justifyContent: 'center',
					}}
				>
					<Box
						sx={{
							// Don't specify height to flex stretch
							// Must specify width because of justifyContent
							width: '100%',
						}}
					>
						{showBackNav && (
							<Container
								ref={backNavRef}
								maxWidth={false}
								sx={combineSx(
									{
										display: {
											xs: 'none',
											mobileMenu: 'block',
										},
										py: 1,
									},
									backNavSx,
								)}
							>
								<BackNavButton
									startIcon={<KeyboardBackspace />}
									backNav={backNav}
									size='small'
								/>
							</Container>
						)}
						<Container
							maxWidth={fullscreen ? false : maxWidth}
							disableGutters={fullscreen}
							sx={combineSx(
								{
									// This should specify the height now
									height: showBackNav
										? `calc(100% - ${backNavHeight || 0}px)`
										: '100%',
								},
								containerSx,
							)}
						>
							{children}
						</Container>
					</Box>
				</Box>
				{showFooter && (
					<Container
						component='footer'
						maxWidth={maxWidth}
						sx={{
							flex: '0 0',
						}}
					>
						{whitelabel.footer}
					</Container>
				)}
			</Stack>
		</BaseTemplate>
	);
};

export default NavTemplate;
