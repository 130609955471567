// TODO: This component needs to be redone with proper classes and inheritance
import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { useApolloClient } from '@apollo/client';
import { MyLocation } from '@mui/icons-material';
import {
	type Breakpoint,
	type SxProps,
	type Theme,
	useMediaQuery,
} from '@mui/material';
import { captureException } from '@sentry/react';
import { type FormikHelpers } from 'formik-othebi';
import isEqual from 'lodash/isEqual';
import { useSnackbar } from 'notistack';
import * as yup from 'yup';

import {
	type Suggestion,
	type FacilitySearchInputFormValues,
} from '@ivy/components/organisms/FacilitySearchInput/facilitySearchInputTypes';
import {
	DEGREE2PROFESSION,
	type ProfDegree,
	Profession,
} from '@ivy/constants/clinician';
import { RouteUri } from '@ivy/constants/routes';
import { useCurrentAccount } from '@ivy/gql/hooks';
import { gql } from '@ivy/gql/types';
import {
	resolvePointToCenter,
	resolveLineStringToViewport,
} from '@ivy/lib/formatting/gMapsLink';
import { getArticle, oxfordJoin } from '@ivy/lib/formatting/string';
import { useDebounce, useForm, useStringifiedMemo } from '@ivy/lib/hooks';
import {
	canGetCurrentLocation,
	type CompleteLocation,
	type FacilityLocation,
	getCurrentPlace,
	getPlaceSuggestions,
	type IncompleteLocation,
	type IncompletePlaceLocation,
	type OrgLocation,
	useGmaps,
	type ViewportPreciseLocation,
	ZOOM_CURRENT_LOCATION,
	type ZoomPreciseLocation,
	type Coordinates,
	type Bounds,
	getPlaceDetails,
	type ResidencyLocation,
	type ClerkshipLocation,
	type FellowshipLocation,
} from '@ivy/lib/services/maps';
import { buildInternalLink } from '@ivy/lib/util/route';

import FacilitySearchInputDesktop from './FacilitySearchInputDesktop';
import FacilitySearchInputMobile from './FacilitySearchInputMobile';

const SEARCH_INPUT_SCHEMA = yup.object({
	location: yup.object().required(),
	profession: yup.string().when('$hideProfession', {
		is: (val: boolean) => val,
		then: (schema) => schema,
		otherwise: (schema) => schema.oneOf(Object.values(Profession)).required(),
	}),
});

const FacilitySearchInput_SearchQDoc = gql(/* GraphQL */ `
	query FacilitySearchInput_Search(
		$search: String!
		$limit: Int!
		$includeFacilities: Boolean!
		$includeOrgs: Boolean!
		$includeResidencies: Boolean!
		$includeClerkships: Boolean!
		$includeFellowships: Boolean!
	) {
		facilities: search_facility_by_prefix(
			args: { search: $search }
			where: { facility: { contracts: { active: { _eq: true } } } }
			order_by: [{ rank: desc }, { id: asc }]
			limit: $limit
		) @include(if: $includeFacilities) {
			id
			facility {
				id
				slug
				name
				city
				state
			}
		}
		orgs: search_organization_by_prefix(
			args: { search: $search }
			order_by: [{ rank: desc }, { id: asc }]
			limit: $limit
		) @include(if: $includeOrgs) {
			id
			org {
				id
				name
				slug
			}
		}
		residencies: search_training_by_prefix(
			args: { search: $search }
			where: { training: { type: { _eq: "RESIDENCY" } } }
			order_by: [{ rank: desc }, { id: asc }]
			limit: $limit
		) @include(if: $includeResidencies) {
			id
			training {
				id
				name
				slug
				residency {
					latestSurvey: latest_survey {
						survey {
							trainingSurvey: training_survey {
								city
								state
							}
						}
					}
				}
			}
		}
		clerkships: search_training_by_prefix(
			args: { search: $search }
			where: { training: { type: { _eq: "CLERKSHIP" } } }
			order_by: [{ rank: desc }, { id: asc }]
			limit: $limit
		) @include(if: $includeClerkships) {
			id
			training {
				id
				name
				slug
				clerkship {
					latestSurvey: latest_survey {
						survey {
							trainingSurvey: training_survey {
								city
								state
							}
						}
					}
				}
			}
		}
		fellowships: search_training_by_prefix(
			args: { search: $search }
			where: { training: { type: { _eq: "FELLOWSHIP" } } }
			order_by: [{ rank: desc }, { id: asc }]
			limit: $limit
		) @include(if: $includeFellowships) {
			id
			training {
				id
				name
				slug
				fellowship {
					latestSurvey: latest_survey {
						survey {
							trainingSurvey: training_survey {
								city
								state
							}
						}
					}
				}
			}
		}
	}
`);

const FacilitySearchInput_GmapLinkQDoc = gql(/* GraphQL */ `
	query FacilitySearchInput_GmapLink($placeId: String!) {
		gmapsLink: gmaps_link(where: { gmaps_place_id: { _eq: $placeId } }) {
			id
			slug
			viewport
			label
			center
			gmapsPlace: gmaps_place {
				id
				geocodePublic: geocode_public
				geocodeShortName: geocode_short_name
				boundaries(
					where: {
						precision: { _eq: "LOW" }
						gmaps_place: {
							geocode_public: {
								_contains: { types: ["administrative_area_level_1"] }
							}
						}
					}
				) {
					id
				}
			}
		}
	}
`);
const SEARCH_FACILITY_NAME_LIMIT = 5;

export interface FacilitySearchInputProps<
	HideProfession extends boolean = false,
> {
	disableLoadGmaps?: boolean;
	location?: IncompleteLocation | CompleteLocation | null;
	profession?: HideProfession extends true ? undefined : Profession;
	disabled?: boolean;
	onSubmit: (
		nl: CompleteLocation,
		np: HideProfession extends true ? '' : Profession,
	) => void;
	mobileEndAdornment?: React.ReactNode;
	mobileBp?: Breakpoint;
	sx?: SxProps<Theme>;
	buttonId?: string;
	hideProfession?: HideProfession;
	types: (
		| 'place'
		| 'facility'
		| 'org'
		| 'residency'
		| 'clerkship'
		| 'fellowship'
	)[];
}

const FacilitySearchInput = <HideProfession extends boolean = false>({
	disableLoadGmaps = false,
	location,
	profession,
	disabled,
	onSubmit,
	mobileEndAdornment,
	mobileBp = 'sm',
	sx,
	buttonId,
	hideProfession = false as HideProfession,
	types = ['place', 'facility', 'org', 'residency', 'clerkship', 'fellowship'],
}: FacilitySearchInputProps<HideProfession>) => {
	const [sessionToken, setSessionToken] =
		useState<google.maps.places.AutocompleteSessionToken | null>(null);
	const [popupOpen, setPopupOpen] = useState(false);
	const isMobile = useMediaQuery(
		(theme: Theme) => theme.breakpoints.down(mobileBp),
		{
			noSsr: true,
		},
	);
	const client = useApolloClient();
	const [search, setSearch] = useState('');
	const [suggestions, setSuggestions] = useState<Suggestion[]>([]);
	const [loadingSuggestions, setLoadingSuggestions] = useState(false);
	const { enqueueSnackbar } = useSnackbar();
	const debouncedSearch = useDebounce(search);
	const hasInternalGmapsLoaded = useGmaps(disableLoadGmaps);
	// If gmaps is loaded externally, then use the disabled flag to inform this component that gmaps hasn't
	// loaded yet. Otherwise, will get "Google Maps hasn't loaded yet" error.
	const isReadyGmaps =
		(!disableLoadGmaps && hasInternalGmapsLoaded) ||
		(disableLoadGmaps && !disabled);
	const componentDisabled = disabled || !isReadyGmaps;

	// Prevent a bug where the array pointer is changing every rerender, causing a hit to the Autocomplete API
	const memoTypes = useStringifiedMemo(types);

	useEffect(() => {
		// Close popup when on desktop
		if (!isMobile) {
			setPopupOpen(false);
		}
	}, [isMobile]);

	const handleSubmit = useCallback(
		async (
			values: FacilitySearchInputFormValues,
			actions: FormikHelpers<FacilitySearchInputFormValues>,
		) => {
			const resolvePlaceId = async (
				placeId: string,
			): Promise<{
				placeId: string;
				center: Coordinates;
				viewport: Bounds;
				slug?: string;
				geocodeTypes?: string[];
				geocodeShortName?: string | null;
				boundaryId?: string;
			}> => {
				const response = await client.query({
					query: FacilitySearchInput_GmapLinkQDoc,
					variables: {
						placeId: placeId,
					},
				});

				if (response.data?.gmapsLink?.length > 0) {
					const foundLink = response.data.gmapsLink[0];

					return {
						placeId,
						center: resolvePointToCenter(foundLink.center),
						viewport: resolveLineStringToViewport(foundLink.viewport),
						slug: foundLink.slug,
						geocodeTypes: foundLink.gmapsPlace.geocodePublic?.types,
						geocodeShortName: foundLink.gmapsPlace.geocodeShortName,
						boundaryId: foundLink.gmapsPlace.boundaries[0]?.id,
					};
				}

				// placeId wasn't tracked or not found in our database.
				// fallback to retrieving data from Google maps' API
				const placeDetails = await getPlaceDetails({
					placeId,
					// These are all included on the basic pricing tier
					// https://developers.google.com/maps/documentation/places/web-service/details
					fields: ['geometry', 'address_components', 'types'],
					sessionToken: sessionToken ?? undefined,
				});
				setSessionToken(null);
				if (
					!placeDetails ||
					!placeDetails.geometry ||
					!placeDetails.geometry.location ||
					!placeDetails.geometry.viewport
				) {
					throw new Error('Place does not exist.');
				}
				const lat = placeDetails.geometry.location.lat();
				const lng = placeDetails.geometry.location.lng();
				const ne = placeDetails.geometry.viewport.getNorthEast();
				const sw = placeDetails.geometry.viewport.getSouthWest();
				const bounds = {
					xMin: sw.lng(),
					xMax: ne.lng(),
					yMin: sw.lat(),
					yMax: ne.lat(),
				};

				return {
					placeId,
					center: { lat, lng },
					viewport: bounds,
					geocodeTypes: placeDetails.types,
					geocodeShortName: placeDetails.address_components?.find((el) =>
						isEqual(el.types, placeDetails.types),
					)?.short_name,
					// Opt: Locate the boundaryId as well using the short name
				};
			};

			const { profession: newProfession, location: newLocation } = values;
			if (!newLocation || (!hideProfession && !newProfession)) {
				actions.setSubmitting(false);
				return;
			}
			const selectedProfession = (
				hideProfession ? '' : newProfession
			) as HideProfession extends true ? '' : Profession;
			if (newLocation.type === 'place') {
				const placeId = (newLocation as IncompletePlaceLocation).placeId;
				// GMaps place
				try {
					const data = await resolvePlaceId(placeId);
					actions.setSubmitting(false);
					setPopupOpen(false);
					onSubmit(
						{
							id: newLocation.id,
							type: newLocation.type,
							label: newLocation.label,
							...data,
						},
						selectedProfession,
					);
				} catch (e) {
					console.error(e);
					enqueueSnackbar(`Unable to look up ${newLocation.label}`, {
						variant: 'error',
					});
					captureException(e, {
						extra: {
							newLocation,
						},
					});
					actions.setSubmitting(false);
					return;
				}
			} else if (
				['facility', 'org', 'residency', 'clerkship', 'fellowship'].includes(
					newLocation.type,
				)
			) {
				const url = (
					newLocation as unknown as
						| FacilityLocation
						| OrgLocation
						| ResidencyLocation
						| ClerkshipLocation
						| FellowshipLocation
				).url;
				actions.setSubmitting(false);
				setPopupOpen(false);
				onSubmit(
					{
						id: newLocation.id,
						type: newLocation.type,
						label: newLocation.label,
						url,
					} as
						| FacilityLocation
						| OrgLocation
						| ResidencyLocation
						| ClerkshipLocation
						| FellowshipLocation,
					selectedProfession,
				);
			} else if (newLocation.type === 'navigator') {
				try {
					// TODO: use geolocation API from GMaps
					if (!canGetCurrentLocation()) {
						throw new Error('Geolocation is not enabled on device.');
					}
					const place = await getCurrentPlace();
					if (!place) {
						throw new Error('Could not determine current location.');
					}
					const geometry = place.geometry;
					const lat = geometry.location.lat();
					const lng = geometry.location.lng();
					// TODO: Convert to place type if can find place_id for the city, since it would be nice to zoom out
					//  to see entire city using viewport
					// TODO: locality may not be accurate since some areas may be neighborhoods or other admin lvls
					const city = place.address_components.find((el) =>
						el.types.includes('locality'),
					)?.long_name;
					const state = place.address_components.find((el) =>
						el.types.includes('administrative_area_level_1'),
					)?.short_name;
					const cityState =
						city && state ? `${city}, ${state}` : 'Current Location';
					actions.setSubmitting(false);
					setPopupOpen(false);
					onSubmit(
						{
							id: `precise-${lat}-${lng}-${ZOOM_CURRENT_LOCATION}`,
							type: 'precise',
							label: cityState,
							center: { lat, lng },
							zoom: ZOOM_CURRENT_LOCATION,
						},
						selectedProfession,
					);
				} catch (e) {
					console.error(e);
					enqueueSnackbar(
						`Unable to determine current location. ${
							e instanceof Error ? e.message : ''
						}.`,
						{
							variant: 'error',
						},
					);
					captureException(e, {
						extra: {
							newLocation,
						},
					});
					actions.setSubmitting(false);
					return;
				}
			} else if (newLocation.type === 'precise') {
				// This case occurs when the user has selected "Current Location" on the landing screen and then
				// changes their profession on the search page to execute a new search.
				actions.setSubmitting(false);
				setPopupOpen(false);
				onSubmit(
					newLocation as unknown as
						| ViewportPreciseLocation
						| ZoomPreciseLocation,
					selectedProfession,
				);
			}
		},
		[
			hideProfession,
			enqueueSnackbar,
			onSubmit,
			setPopupOpen,
			client,
			sessionToken,
			setSessionToken,
		],
	);

	const currAcc = useCurrentAccount();
	const formik = useForm({
		initialValues: {
			location: location || null,
			profession: hideProfession
				? ''
				: profession ||
				  (currAcc?.isClinician
						? DEGREE2PROFESSION[currAcc.clinician!.profDegree as ProfDegree]
						: ''),
		} as FacilitySearchInputFormValues,
		context: {
			hideProfession,
		},
		validationSchema: SEARCH_INPUT_SCHEMA,
		onSubmit: handleSubmit,
		enableReinitialize: true,
	});

	const getFormattedEntitySuggestions = useCallback(
		async (
			input: string,
		): Promise<
			[Suggestion[], Suggestion[], Suggestion[], Suggestion[], Suggestion[]]
		> => {
			if (
				!memoTypes.includes('facility') &&
				!memoTypes.includes('org') &&
				!memoTypes.includes('residency') &&
				!memoTypes.includes('clerkship') &&
				!memoTypes.includes('fellowship')
			) {
				return [[], [], [], [], []];
			}

			const response = await client.query({
				query: FacilitySearchInput_SearchQDoc,
				variables: {
					search: input,
					limit: SEARCH_FACILITY_NAME_LIMIT,
					includeFacilities: memoTypes.includes('facility'),
					includeOrgs: memoTypes.includes('org'),
					includeResidencies: memoTypes.includes('residency'),
					includeClerkships: memoTypes.includes('clerkship'),
					includeFellowships: memoTypes.includes('fellowship'),
				},
			});
			return [
				response.data.facilities?.map(({ facility }) => ({
					secondary: `${facility!.city}, ${facility!.state}`,
					location: {
						id: `facility-${facility!.id}`,
						label: facility!.name,
						type: 'facility',
						url: buildInternalLink(RouteUri.FACILITY_SHOW, {
							facilityId: [facility!.id, facility!.slug],
						}),
					} as FacilityLocation,
				})) || [],
				response.data.orgs?.map(({ org }) => ({
					location: {
						id: `org-${org!.id}`,
						type: 'org',
						label: org!.name,
						url: buildInternalLink(RouteUri.ORG_SHOW, {
							orgId: [org!.id, org!.slug],
						}),
					} as OrgLocation,
				})) || [],
				response.data.residencies?.map(({ training }) => ({
					secondary: `${
						training!.residency!.latestSurvey!.survey!.trainingSurvey.city
					}, ${
						training!.residency!.latestSurvey!.survey!.trainingSurvey.state
					}`,
					location: {
						id: `residency-${training!.id}`,
						label: training!.name,
						type: 'residency',
						url: buildInternalLink(RouteUri.EMRA_RESIDENCY_SHOW, {
							trainingId: [training!.id, training!.slug],
						}),
					} as ResidencyLocation,
				})) || [],
				response.data.clerkships?.map(({ training }) => ({
					secondary: `${
						training!.clerkship!.latestSurvey!.survey!.trainingSurvey.city
					}, ${
						training!.clerkship!.latestSurvey!.survey!.trainingSurvey.state
					}`,
					location: {
						id: `clerkship-${training!.id}`,
						label: training!.name,
						type: 'clerkship',
						url: buildInternalLink(RouteUri.EMRA_CLERKSHIP_SHOW, {
							trainingId: [training!.id, training!.slug],
						}),
					} as ClerkshipLocation,
				})) || [],
				response.data.fellowships?.map(({ training }) => ({
					secondary: `${
						training!.fellowship!.latestSurvey!.survey!.trainingSurvey.city
					}, ${
						training!.fellowship!.latestSurvey!.survey!.trainingSurvey.state
					}`,
					location: {
						id: `fellowship-${training!.id}`,
						label: training!.name,
						type: 'fellowship',
						url: buildInternalLink(RouteUri.EMRA_FELLOWSHIP_SHOW, {
							trainingId: [training!.id, training!.slug],
						}),
					} as FellowshipLocation,
				})) || [],
			];
		},
		[client, memoTypes],
	);

	const getFormattedPlaceSuggestions = useCallback(
		async (input: string): Promise<Suggestion[]> => {
			if (!memoTypes.includes('place')) {
				return [];
			}
			let token: google.maps.places.AutocompleteSessionToken;
			if (sessionToken) {
				token = sessionToken;
			} else {
				token = new google.maps.places.AutocompleteSessionToken();
				setSessionToken(token);
			}
			const places = await getPlaceSuggestions({
				input,
				componentRestrictions: {
					country: 'us',
				},
				types: ['locality', 'administrative_area_level_1'],
				sessionToken: token,
			});
			return places.map((place) => ({
				location: {
					id: `place-${place.place_id}`,
					type: 'place',
					// Description includes "USA," so don't use it
					// label: place.description,
					label: place.terms
						.slice(0, place.terms.length - 1)
						.map((term) => term.value)
						.join(', '),
					placeId: place.place_id,
				},
			}));
		},
		[memoTypes, sessionToken, setSessionToken],
	);

	const populateSuggestions = useCallback(
		async (input: string) => {
			if (!input && memoTypes.includes('place')) {
				setSuggestions([
					{
						icon: <MyLocation color='primary' />,
						location: {
							id: 'navigator',
							type: 'navigator',
							label: 'Current location',
						},
					},
				]);
				return;
			}

			setLoadingSuggestions(true);
			try {
				const [
					placeSuggestions,
					[
						facilitySuggestions,
						orgSuggestions,
						residencySuggestions,
						clerkshipSuggestions,
						fellowshipSuggestions,
					],
				] = await Promise.all([
					getFormattedPlaceSuggestions(input),
					getFormattedEntitySuggestions(input),
				]);
				if (memoTypes.length > 1) {
					if (placeSuggestions.length) {
						placeSuggestions[0].section = 'Places';
					}
					if (facilitySuggestions.length) {
						facilitySuggestions[0].section = 'Facilities';
					}
					if (orgSuggestions.length) {
						orgSuggestions[0].section = 'Employers';
					}
					if (residencySuggestions.length) {
						residencySuggestions[0].section = 'Residencies';
					}
					if (clerkshipSuggestions.length) {
						clerkshipSuggestions[0].section = 'Clerkships';
					}
					if (fellowshipSuggestions.length) {
						fellowshipSuggestions[0].section = 'Fellowships';
					}
				}
				// Sort by the order specified in types
				const newSuggestions = [
					['place', placeSuggestions],
					['facility', facilitySuggestions],
					['org', orgSuggestions],
					['residency', residencySuggestions],
					['clerkship', clerkshipSuggestions],
					['fellowship', fellowshipSuggestions],
				]
					.sort(
						(a, b) =>
							memoTypes.indexOf(
								a[0] as
									| 'place'
									| 'facility'
									| 'org'
									| 'residency'
									| 'clerkship'
									| 'fellowship',
							) -
							memoTypes.indexOf(
								b[0] as
									| 'place'
									| 'facility'
									| 'org'
									| 'residency'
									| 'clerkship'
									| 'fellowship',
							),
					)
					.map((el) => el[1])
					.flat() as Suggestion[];
				setSuggestions(newSuggestions);
			} catch (e) {
				console.error(e);
				enqueueSnackbar('An error occurred, please try again.', {
					variant: 'error',
				});
				captureException(e, {
					extra: {
						input,
					},
				});
			} finally {
				setLoadingSuggestions(false);
			}
		},
		[
			memoTypes,
			setSuggestions,
			setLoadingSuggestions,
			getFormattedPlaceSuggestions,
			getFormattedEntitySuggestions,
			enqueueSnackbar,
		],
	);

	useEffect(() => {
		if (isReadyGmaps) {
			populateSuggestions(debouncedSearch);
		}
	}, [isReadyGmaps, debouncedSearch, populateSuggestions]);

	const disabledSubmit = useMemo(() => {
		// On the ForClinicians page, search button is enabled (empty inputs).
		// However, on the search page (populated inputs), the search button is only enabled when dirty
		// because it becomes really confusing if you need to press it again or not when the filters
		// are also present. The change from disabled -> enabled makes it really clear if you need to
		// press the button to update your search.
		const dirtyCheck = !!location && (hideProfession || profession);
		return dirtyCheck && (!formik.dirty || !formik.isValid);
	}, [location, hideProfession, profession, formik.dirty, formik.isValid]);

	const placeholder = useMemo(() => {
		const joinedTypes = oxfordJoin(
			memoTypes.map((el) => (el === 'org' ? 'employer' : el)),
			'or',
		);
		return `Enter ${getArticle(joinedTypes)} ${joinedTypes}`;
	}, [memoTypes]);

	if (isMobile) {
		return (
			<FacilitySearchInputMobile
				formik={formik}
				search={search}
				setSearch={setSearch}
				suggestions={suggestions}
				loadingSuggestions={loadingSuggestions}
				disabled={componentDisabled}
				disabledSubmit={disabledSubmit}
				sx={sx}
				popupOpen={popupOpen}
				setPopupOpen={setPopupOpen}
				endAdornment={mobileEndAdornment}
				buttonId={buttonId}
				hideProfession={hideProfession}
				placeholder={placeholder}
			/>
		);
	}

	return (
		<FacilitySearchInputDesktop
			formik={formik}
			search={search}
			setSearch={setSearch}
			suggestions={suggestions}
			loadingSuggestions={loadingSuggestions}
			disabled={componentDisabled}
			disabledSubmit={disabledSubmit}
			sx={sx}
			buttonId={buttonId}
			hideProfession={hideProfession}
			placeholder={placeholder}
		/>
	);
};

export default FacilitySearchInput;
